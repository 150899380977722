import React from 'react';
import ViewPaymentInfo from '../View/PaymentInfo';
import { useSubscription } from 'context/subscription';

const SectionActivePayment = () => {

  const { dealerStore } = useSubscription();

  return <ViewPaymentInfo dealerStore={dealerStore} hasEdit={false} />
}

export default SectionActivePayment;
