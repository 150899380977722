import React, { useCallback, useState } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { Row, Card, CardBody, CardHeader, Table, Accordion, AccordionItem, Collapse } from "reactstrap";
import Col from "components/Shared/Col";
import { getVidRequestNormListItem } from "store/actions";
import { getBeUrl } from "helpers/utilHelper";
import config from "config";
import NoImg from "components/Shared/NoImg";
import classnames from "classnames";
import VidRequest from "model/vidRequest";
import { formatTimestamp, formats, formatUtcDate } from "helpers/dateHelper";
import VidAlertDesc from "constants/vidAlertDesc";
import { Gallery, Item } from 'react-photoswipe-gallery';
import { VERIF_TYPE_DMV_CHECK } from "constants/mitek";
import { useSocketOn, useSubscribeToVidReq } from "hooks/socket";
import socketEvent from "constants/socketEvent";
import vidSkippedIcon from "assets/images/identity-validation-skipped.svg";
import skippedVidIcon from "assets/images/skipped-VID.svg";
import isolationIcon from "assets/images/isolation-icon.svg";

const VidReq = props => {

  const { id } = props;

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  /********** STATE **********/

  const vidRequest = useSelector(state => state.VidRequest.NormList.vidRequests[id]);

  const [activeAccItem, setActiveAccItem] = useState(null);

  /********** OTHER **********/

  const refreshVidRequest = useCallback(() => dispatch(getVidRequestNormListItem(vidRequest.id)), [vidRequest.id]);

  /********** SOCKET **********/

  // start receiving updates about this particular vid request
  useSubscribeToVidReq(vidRequest.id);

  const onVidRequestChanged = useCallback(data => {
    // this socket client is shared by the entire app
    // and here we are listening for an event that might be triggered by multiple vid requests
    // therefore we need to check whether this update refers to the current vid request
    if (data.id == vidRequest.id) {
      refreshVidRequest();
    }
  }, [vidRequest.id, refreshVidRequest]);

  // listen for changes on order documents
  useSocketOn(socketEvent.vidReqChanged, onVidRequestChanged);

  /********** EVENT HANDLERS **********/

  const toggleAccItem = itemNum => {
    if (activeAccItem == itemNum) {
      setActiveAccItem(null);
    } else {
      setActiveAccItem(itemNum);
    }
  }

  /********** OTHER **********/

  let sortedVerifications = []

  // move DMV Check verification to the beginning of the list
  if (vidRequest?.autoResponse?.verifications) {
    const { autoResponse: { verifications } } = vidRequest;

    sortedVerifications = verifications.filter(v => v.verificationType !== VERIF_TYPE_DMV_CHECK);

    const dmvCheckVerification = verifications.find(v => v.verificationType === VERIF_TYPE_DMV_CHECK);
    dmvCheckVerification && sortedVerifications.unshift(dmvCheckVerification);
  }

  const isAddSigner = vidRequest.signer.position > 1;

  let customerName = '--',
    customerAddress = '--',
    customerBirthDate = '--',
    customerDocumentNumber = '--',
    customerExpirationDate = '--';
  if (vidRequest) {
    if (vidRequest.autoResponse) {
      const customerInfo = vidRequest.autoResponse.customerInfo;
      customerName = customerInfo.name.fullName;
      customerAddress = `${customerInfo.address.addressLine1}, ${customerInfo.address.city}, ${customerInfo.address.stateProvince}, ${customerInfo.address.postalCode}`;
      // we do not want timezone conversion here so make sure to format in UTC
      customerBirthDate = formatUtcDate(Date.parse(customerInfo.birthDate), formats.US_DATE);
      customerDocumentNumber = customerInfo.documentNumber;
      // we do not want timezone conversion here so make sure to format in UTC
      customerExpirationDate = formatUtcDate(Date.parse(customerInfo.expirationDate), formats.US_DATE);
    } else {
      customerName = vidRequest.signer.firstName + ' ' + vidRequest.signer.lastName;
    }
  }

  const getImageUrl = image => getBeUrl(`vid-request/${vidRequest.id}/image/${image}`);

  return <React.Fragment>
    {vidRequest && <Row>
      <Col xl="8" className="d-flex flex-column">
        <Row>
          <Col>
            <Card className={classnames({ blue: isAddSigner })}>
              <CardBody>
                <Gallery options={{ mainClass: 'vid-request-gallery' }}>
                  <Row>
                    {vidRequest.idType != VidRequest.ID_TYPE_PASSPORT && <React.Fragment>
                      <Col>
                        <strong className="vid-image-title mb-3">Front of the ID</strong>
                        {!!vidRequest.hasFrontImage && <div className="vid-image">
                          <Item content={<div className="vid-image-preview"><img src={getImageUrl(config.VID_REQ_IMAGE_FRONT)} /></div>}>
                            {({ ref, open }) => (<img src={getImageUrl(config.VID_REQ_IMAGE_FRONT)} ref={ref} onClick={open} />)}
                          </Item>
                        </div>}
                        {!vidRequest.hasFrontImage && <NoImg />}
                      </Col>
                      <Col>
                        <strong className="vid-image-title mb-3">Back of the ID</strong>
                        {!!vidRequest.hasBackImage && <div className="vid-image">
                          <Item content={<div className="vid-image-preview"><img src={getImageUrl(config.VID_REQ_IMAGE_BACK)} /></div>}>
                            {({ ref, open }) => (<img src={getImageUrl(config.VID_REQ_IMAGE_BACK)} ref={ref} onClick={open} />)}
                          </Item>
                        </div>}
                        {!vidRequest.hasBackImage && <NoImg />}
                      </Col>
                      {!!vidRequest.isSelfieRequired &&
                        <Col>
                          <strong className="vid-image-title mb-3">Selfie</strong>
                          {!!vidRequest.hasSelfieImage && <div className="vid-image">
                            <Item content={<div className="vid-image-preview"><img src={getImageUrl(config.VID_REQ_IMAGE_SELFIE)} /></div>}>
                              {({ ref, open }) => (<img src={getImageUrl(config.VID_REQ_IMAGE_SELFIE)} ref={ref} onClick={open} />)}
                            </Item>
                          </div>}
                          {!vidRequest.hasSelfieImage && <NoImg />}
                        </Col>
                      }
                    </React.Fragment>}
                    {vidRequest.idType == VidRequest.ID_TYPE_PASSPORT && <React.Fragment>
                      <Col>
                        <strong className="vid-image-title mb-3">Passport</strong>
                        {!!vidRequest.hasPassportImage && <div className="vid-image">
                          <Item content={<div className="vid-image-preview"><img src={getImageUrl(config.VID_REQ_IMAGE_PASSPORT)} /></div>}>
                            {({ ref, open }) => (<img src={getImageUrl(config.VID_REQ_IMAGE_PASSPORT)} ref={ref} onClick={open} />)}
                          </Item>
                        </div>}
                        {!vidRequest.hasPassportImage && <NoImg />}
                      </Col>
                      <Col>
                        <strong className="vid-image-title mb-3">Selfie</strong>
                        {!!vidRequest.hasSelfieImage && <div className="vid-image">
                          <Item content={<div className="vid-image-preview"><img src={getImageUrl(config.VID_REQ_IMAGE_SELFIE)} /></div>}>
                            {({ ref, open }) => (<img src={getImageUrl(config.VID_REQ_IMAGE_SELFIE)} ref={ref} onClick={open} />)}
                          </Item>
                        </div>}
                        {!vidRequest.hasSelfieImage && <NoImg />}
                      </Col>
                    </React.Fragment>}
                  </Row>
                </Gallery>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {vidRequest?.status === VidRequest.STATUS_SKIPPED && <Row>
          <Col>
            <Card className="text-center">
              <CardHeader className="bg-transparent pt-3 pb-0">
                <Row>
                  <Col>
                    <div className="card-title my-2">Identity Validation Skipped</div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <img className="mb-2" src={vidSkippedIcon} />
              </CardBody>
            </Card>
          </Col>
        </Row>}
        <Row className="flex-grow-1">
          <Col>
            <Card className={classnames('expand-v', { blue: isAddSigner })}>
              <CardHeader className="bg-transparent pt-3 pb-0">
                <Row>
                  <Col>
                    <div className="card-title mt-2 mb-0">{isAddSigner ? 'Additional signer information' : 'Customer information'}</div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table className="section-rows table bt-1">
                  <tbody>
                    <tr>
                      <td className="section-row-label">Customer</td>
                      <td className="section-row-value">{customerName}</td>
                    </tr>
                    <tr>
                      <td className="section-row-label">Customer address</td>
                      <td className="section-row-value">{customerAddress}</td>
                    </tr>
                    <tr>
                      <td className="section-row-label">Date of birth</td>
                      <td className="section-row-value">{customerBirthDate}</td>
                    </tr>
                    <tr>
                      <td className="section-row-label">Document number</td>
                      <td className="section-row-value">{customerDocumentNumber}</td>
                    </tr>
                    <tr>
                      <td className="section-row-label">Document expiration date</td>
                      <td className="section-row-value">{customerExpirationDate}</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col xl="4">
        <Card className={classnames('vid-response-card', { blue: isAddSigner })}>
          <CardBody>
            <p><strong>Status:</strong> <strong className={`text-${VidRequest.getStatusColor(vidRequest.status)}`}>{vidRequest.statusName}</strong></p>
            {vidRequest.status == VidRequest.STATUS_SKIPPED &&
              <div className="pt-1 pb-3">
                <span><b>Reason of skipping: </b></span><br />
                <span>{VidRequest.getSkipReason(vidRequest.skipReason) || '--'}</span>
                <br />
                <span><b>Skip Details: </b></span><br />
                <span>{vidRequest.skipDetails || '--'}</span>
              </div>
            }
            <p><strong>Date:</strong> {vidRequest.updatedTs ? formatTimestamp(vidRequest.updatedTs, formats.DATETIME) : '--'}</p>
            {vidRequest.assistStatus > 0 &&
              <div className="vid-accordion-container mb-4">
                <div className="vid-accordion-assist">
                  <span><b>Agent Assist Status: </b></span>
                  <span>{VidRequest.getStatusName(vidRequest.assistStatus)}</span>
                </div>
                {vidRequest.assistResponse && vidRequest.assistResponse.details && <>
                  <div className="vid-accordion-assist">
                    <span><b>Document Authenticity: </b></span>
                    <span>{vidRequest.assistResponse.details.authenticity.result}</span>
                  </div>
                  <div className="vid-accordion-assist">
                    <span><b>Document Originality: </b></span>
                    <span>{vidRequest.assistResponse.details.originality.result}</span>
                  </div>
                  <div className="vid-accordion-assist">
                    <span><b>Document Validity:  </b></span>
                    <span>{vidRequest.assistResponse.details.validity.result}</span>
                  </div>
                </>}
                {vidRequest.assistResponse && vidRequest.assistResponse.result && <>
                  <div className="vid-accordion-assist">
                    <span><b>Assist reason: </b></span>
                    <span>{vidRequest.assistResponse.reasons[0]}</span>
                  </div>
                </>}
              </div>
            }
            {vidRequest.hasSignatureImage &&
              <div className="d-flex justify-content-between align-items-center">
                <p className="font-size-16"><strong>Customer signature:</strong></p>
                <img className="vid-signature-image" src={getImageUrl(config.VID_REQ_IMAGE_SIGNATURE)} />
              </div>
            }
            {vidRequest?.status === VidRequest.STATUS_SKIPPED && <div className="identity-validation-skipped mt-5">
              <div className="d-flex">
                <img src={isolationIcon} />
                <div className="ms-3">
                  <div className="d-flex align-items-center mb-2">
                    <img src={skippedVidIcon} />
                    <div className="title">Identity Validation Skipped</div>
                  </div>
                  <div>The Identity Validation process was Skipped.</div>
                </div>
              </div>
            </div>}
            {!!vidRequest.autoResponse && <React.Fragment>
              <p className="mb-2"><strong>Verifications alerts:</strong></p>
              <div className="vid-accordion-container">
                <div className="vid-accordion-head">
                  <span>NAME</span>
                  <span>SCORE</span>
                </div>
                <div className="vid-accordion-list">
                  <Accordion flush>
                    {sortedVerifications.map((item, index) => {
                      const score = parseInt(item.probability / 1000 * 100);
                      return <AccordionItem key={index}>
                        <h2 className="accordion-header">
                          <button type="button" className={classnames('accordion-button fw-medium', { collapsed: activeAccItem != index })} onClick={() => toggleAccItem(index)}>
                            <span>{item.name}</span>
                            <strong className={classnames({ 'text-danger': score < 50, 'text-warning': score < 100, 'text-success': score == 100 })} data-probability={item.probability}>{score}%</strong>
                          </button>
                        </h2>
                        <Collapse isOpen={activeAccItem == index} className="accordion-collapse">
                          <div className="accordion-body">
                            {!!VidAlertDesc[item.verificationType] && <p className="vid-alert-desc">{VidAlertDesc[item.verificationType]}</p>}
                            {Object.keys(item.notifications).length > 0 && <div>
                              <p className="mb-1">Notification:</p>
                              <ul className="vid-alert-notifications">
                                {Object.entries(item.notifications).map((notification, index2) => <li key={index + '-' + index2} className="mb-1">({notification[0]}) {notification[1]}</li>)}
                              </ul>
                            </div>}
                          </div>
                        </Collapse>
                      </AccordionItem>
                    })}
                  </Accordion>
                </div>
              </div>
            </React.Fragment>}
          </CardBody>
        </Card>
      </Col>
    </Row>}
  </React.Fragment>
}

VidReq.propTypes = {
  id: PropTypes.number.isRequired,
};

export default VidReq;
