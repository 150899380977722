import React from 'react';
import PropTypes from 'prop-types';
import ActivityLog from 'model/activityLog';
import OrderVehicleUpdated from './OrderVehicle/OrderVehicleUpdated';
import OrderCreated from './Order/OrderCreated';
import OrderSubmitted from './Order/OrderSubmitted';
import OrderCancelled from './Order/OrderCancelled';
import OrderContractChanged from './Order/OrderContractChanged';
import OrderSchedulerAssigned from './Order/OrderSchedulerAssigned';
import OrderStatusChanged from './Order/OrderStatusChanged';
import OrderDocCreated from './OrderDoc/OrderDocCreated';
import NotImplemented from './NotImplemented';
import OrderSignerCreated from './OrderSigner/OrderSignerCreated';
import OrderSignerUpdated from './OrderSigner/OrderSignerUpdated';
import OrderSignerDeleted from './OrderSigner/OrderSignerDeleted';
import OrderSignerSmsOptIn from './OrderSigner/OrderSignerSmsOptIn';
import OrderSignerSmsOptOut from './OrderSigner/OrderSignerSmsOptOut';
import OrderDocUpdated from './OrderDoc/OrderDocUpdated';
import OrderDocSigned from './OrderDoc/OrderDocSigned';
import OrderDocAccepted from './OrderDoc/OrderDocAccepted';
import OrderDocRejected from './OrderDoc/OrderDocRejected';
import OrderDocDeleted from './OrderDoc/OrderDocDeleted';
import OrderSealStarted from './OrderSeal/OrderSealStarted';
import OrderSealSuccessful from './OrderSeal/OrderSealSuccessful';
import OrderSealFailed from './OrderSeal/OrderSealFailed';
import VidAutoStarted from './VidRequest/VidAutoStarted';
import VidAutoSuccess from './VidRequest/VidAutoSuccess';
import VidAutoFailed from './VidRequest/VidAutoFailed';
import VidManualStarted from './VidRequest/VidManualStarted';
import VidManualSuccess from './VidRequest/VidManualSuccess';
import VidManualFailed from './VidRequest/VidManualFailed';
import MeetingCreated from './Meeting/MeetingCreated';
import MeetingStarted from './Meeting/MeetingStarted';
import MeetingEnded from './Meeting/MeetingEnded';
import MeetingParticipantJoined from './Meeting/MeetingParticipantJoined';
import MeetingParticipantLeft from './Meeting/MeetingParticipantLeft';
import OrderDocStatusChanged from './OrderDoc/OrderDocStatusChanged';
import OrderVehicleDeleted from './OrderVehicle/OrderVehicleDeleted';
import OrderVehicleCreated from './OrderVehicle/OrderVehicleCreated';
import OrderNotaryAssigned from "./OrderNotary/OrderNotaryAssigned";
import OrderNotaryViewed from "./OrderNotary/OrderNotaryViewed";
import OrderNotaryDocsReceived from "./OrderNotary/OrderNotaryDocsReceived";
import OrderNotaryAppointmentSet from "./OrderNotary/OrderNotaryAppointmentSet";
import OrderNotaryAppointmentDeclined from "./OrderNotary/OrderNotaryAppointmentDeclined";
import OrderNotaryDocsPrinted from "./OrderNotary/OrderNotaryDocsPrinted";
import OrderNotaryDocsUploaded from "./OrderNotary/OrderNotaryDocsUploaded";
import OrderNotaryOrderCompleted from "./OrderNotary/OrderNotaryOrderCompleted";
import TermsAccepted from './Order/TermsAccepted';

const ActivityEntry = props => {

  const { log } = props;

  let Component = null;

  switch (log.event) {

    /***** ORDER *****/
    case ActivityLog.EVENT_ORDER_CREATED:
      Component = OrderCreated;
      break;
    case ActivityLog.EVENT_ORDER_SUBMITTED:
      Component = OrderSubmitted
      break;
    case ActivityLog.EVENT_ORDER_CANCELLED:
      Component = OrderCancelled;
      break;
    case ActivityLog.EVENT_ORDER_SEAL_STARTED:
      Component = OrderSealStarted;
      break;
    case ActivityLog.EVENT_ORDER_SEAL_SUCCESS:
      Component = OrderSealSuccessful;
      break;
    case ActivityLog.EVENT_ORDER_SEAL_FAILED:
      Component = OrderSealFailed;
      break;
    case ActivityLog.EVENT_ORDER_STATUS_CHANGED:
      Component = OrderStatusChanged;
      break;
    case ActivityLog.EVENT_ORDER_CONTRACT_DETAILS_CHANGED:
      Component = OrderContractChanged;
      break;
    case ActivityLog.EVENT_ORDER_SCHEDULER_ASSIGNED:
      Component = OrderSchedulerAssigned;
      break;
    case ActivityLog.EVENT_ORDER_TERMS_ACCEPTED:
      Component = TermsAccepted;
      break;

    /***** MEETING *****/
    case ActivityLog.EVENT_MEETING_CREATED:
      Component = MeetingCreated;
      break;
    case ActivityLog.EVENT_MEETING_STARTED:
      Component = MeetingStarted;
      break;
    case ActivityLog.EVENT_MEETING_ENDED:
      Component = MeetingEnded;
      break;
    case ActivityLog.EVENT_MEETING_PARTICIPANT_JOINED:
      Component = MeetingParticipantJoined;
      break;
    case ActivityLog.EVENT_MEETING_PARTICIPANT_LEFT:
      Component = MeetingParticipantLeft;
      break;

    /***** ORDER DOC *****/
    case ActivityLog.EVENT_ORDER_DOC_CREATED:
      Component = OrderDocCreated;
      break;
    case ActivityLog.EVENT_ORDER_DOC_UPDATED:
      Component = OrderDocUpdated;
      break;
    case ActivityLog.EVENT_ORDER_DOC_SIGNED:
      Component = OrderDocSigned;
      break;
    case ActivityLog.EVENT_ORDER_DOC_ACCEPTED:
      Component = OrderDocAccepted;
      break;
    case ActivityLog.EVENT_ORDER_DOC_REJECTED:
      Component = OrderDocRejected;
      break;
    case ActivityLog.EVENT_ORDER_DOC_DELETED:
      Component = OrderDocDeleted;
      break;
    case ActivityLog.EVENT_ORDER_DOC_STATUS_CHANGED:
      Component = OrderDocStatusChanged;
      break;

    /***** ORDER SIGNER *****/
    case ActivityLog.EVENT_ORDER_SIGNER_CREATED:
      Component = OrderSignerCreated;
      break;
    case ActivityLog.EVENT_ORDER_SIGNER_UPDATED:
      Component = OrderSignerUpdated;
      break;
    case ActivityLog.EVENT_ORDER_SIGNER_DELETED:
      Component = OrderSignerDeleted;
      break;
    case ActivityLog.EVENT_ORDER_SIGNER_SMS_OPT_IN:
      Component = OrderSignerSmsOptIn;
      break;
    case ActivityLog.EVENT_ORDER_SIGNER_SMS_OPT_OUT:
      Component = OrderSignerSmsOptOut;
      break;

    /***** ORDER NOTARY *****/
    case ActivityLog.EVENT_ORDER_NOTARY_ASSIGNED:
      Component = OrderNotaryAssigned;
      break;
    case ActivityLog.EVENT_ORDER_NOTARY_VIEWED:
      Component = OrderNotaryViewed;
      break;
    case ActivityLog.EVENT_ORDER_NOTARY_DOCS_RECEIVED:
      Component = OrderNotaryDocsReceived;
      break;
    case ActivityLog.EVENT_ORDER_NOTARY_APPOINTMENT_SET:
      Component = OrderNotaryAppointmentSet;
      break;
    case ActivityLog.EVENT_ORDER_NOTARY_APPOINTMENT_DECLINED:
      Component = OrderNotaryAppointmentDeclined;
      break;
    case ActivityLog.EVENT_ORDER_NOTARY_DOCS_PRINTED:
      Component = OrderNotaryDocsPrinted;
      break;
    case ActivityLog.EVENT_ORDER_NOTARY_DOCS_UPLOADED:
      Component = OrderNotaryDocsUploaded;
      break;
    case ActivityLog.EVENT_ORDER_NOTARY_ORDER_COMPLETED:
      Component = OrderNotaryOrderCompleted;
      break;

    /***** VID REQUEST *****/
    case ActivityLog.EVENT_VID_AUTO_STARTED:
      Component = VidAutoStarted;
      break;
    case ActivityLog.EVENT_VID_AUTO_SUCCESSFUL:
      Component = VidAutoSuccess;
      break;
    case ActivityLog.EVENT_VID_AUTO_FAILED:
      Component = VidAutoFailed;
      break;
    case ActivityLog.EVENT_VID_MANUAL_STARTED:
      Component = VidManualStarted;
      break;
    case ActivityLog.EVENT_VID_MANUAL_SUCCESSFUL:
      Component = VidManualSuccess;
      break;
    case ActivityLog.EVENT_VID_MANUAL_FAILED:
      Component = VidManualFailed;
      break;

    /***** VEHICLE *****/

    case ActivityLog.EVENT_ORDER_VEHICLE_CREATED:
      Component = OrderVehicleCreated;
      break;
    case ActivityLog.EVENT_ORDER_VEHICLE_UPDATED:
      Component = OrderVehicleUpdated;
      break;
    case ActivityLog.EVENT_ORDER_VEHICLE_DELETED:
      Component = OrderVehicleDeleted;
      break;

    default:
      Component = NotImplemented;
      break;
  }

  return <li className="event-list">
    <div className="event-timeline-dot">
      <i className="bx bx-right-arrow-circle font-size-18"></i>
    </div>
    <Component {...props} />
  </li>
}

ActivityEntry.propTypes = {
  log: PropTypes.object,
}

export default ActivityEntry;