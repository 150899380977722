import OrderNotary from "./orderNotary";

export default class Order {

  static STATUS_CANCELLED = -1;
  static STATUS_DRAFT = 0;
  static STATUS_PENDING_VID = 1;
  static STATUS_NEW = 2;
  static STATUS_PICK_NOTARY = 3;
  static STATUS_PENDING_E_SIGN = 4;
  static STATUS_PENDING_INK_SIGN = 5;
  static STATUS_COMPLETED = 6;
  static STATUS_SEALED = 7;

  static INTERNAL_STATUS_UNDER_REVIEW = 1;
  static INTERNAL_STATUS_ON_HOLD = 2;

  static GRANULAR_STATUS_TYPE_INTERNAL = 0;
  static GRANULAR_STATUS_TYPE_ORDER = 1;
  static GRANULAR_STATUS_TYPE_ORDER_NOTARY = 2;

  static CUSTOMER_LOCATION_REMOTE = 1;
  static CUSTOMER_LOCATION_IN_STORE = 2;

  static CONTRACT_OPTION_VID = 1;
  static CONTRACT_OPTION_SIGNING = 2;
  static CONTRACT_OPTION_SIGNING_VID = 3;

  static SEAL_STATUS_QUEUED = 1;
  static SEAL_STATUS_FAILED = 2;
  static SEAL_STATUS_SEALED = 3;

  static DOC_DELIVERY_OPTION_UPLOAD = 1;
  static DOC_DELIVERY_OPTION_SHIPPING = 2;

  static SHIPPING_COMPANY_OTHER = 1;
  static SHIPPING_COMPANY_FEDEX = 2;
  static SHIPPING_COMPANY_UPS = 3;

  static CONTRACT_TYPE_ORIGINAL = 1;
  static CONTRACT_TYPE_RESIGN = 2;

  static ITEM_TO_COLLECT_PROOF_OF_RESIDENCY = 1;
  static ITEM_TO_COLLECT_PROOF_OF_INCOME = 2;
  static ITEM_TO_COLLECT_PROOF_OF_INSURANCE = 3;
  static ITEM_TO_COLLECT_DRIVER_LICENSE_COPY = 4;

  static ALERT_FILTER_ON_HOLD = 1;
  static ALERT_FILTER_UNDER_REVIEW = 2;
  static ALERT_FILTER_HIGH_RISK = 3;
  static ALERT_FILTER_FRAUD = 4;
  static ALERT_FILTER_INACTIVE = 5;


  static getStatusColor(status) {
    switch (status) {
      case this.STATUS_DRAFT:
        return 'draft';
      case this.STATUS_PENDING_VID:
        return 'pending-vid';
      case this.STATUS_NEW:
        return 'new';
      case this.STATUS_PICK_NOTARY:
        return 'pick';
      case this.STATUS_PENDING_E_SIGN:
        return 'pending-eSign';
      case this.STATUS_PENDING_INK_SIGN:
        return 'pending-inkSign';
      case this.STATUS_COMPLETED:
        return 'completed';
      case this.STATUS_SEALED:
        return 'sealed';
      case this.STATUS_CANCELLED:
        return 'cancelled';
      default:
        return 'dark';
    }
  }

  static getGranularStatusColor(status) {
    if (!status) return 'dark';

    const [statusType, statusId] = status.split("_").map(id => parseInt(id));

    switch (statusType) {
      case this.GRANULAR_STATUS_TYPE_INTERNAL:
        return 'secondary';
      case this.GRANULAR_STATUS_TYPE_ORDER_NOTARY:
        return OrderNotary.getGranularStatusColor(statusId);
      case this.GRANULAR_STATUS_TYPE_ORDER:
      default:
        return this.getStatusColor(statusId);
    }
  }

  static getStatusMap() {
    return {
      [this.STATUS_CANCELLED]: 'Canceled',
      [this.STATUS_DRAFT]: 'Draft',
      [this.STATUS_PENDING_VID]: 'Pending VID',
      [this.STATUS_NEW]: 'New Order',
      [this.STATUS_PICK_NOTARY]: 'Pick Notary',
      [this.STATUS_PENDING_E_SIGN]: 'Pending E-sign',
      [this.STATUS_PENDING_INK_SIGN]: 'Pending Ink-sign',
      [this.STATUS_COMPLETED]: 'Completed',
      [this.STATUS_SEALED]: 'Sealed',
    };
  }

  static getStatusName(id) {
    return this.getStatusMap()[id];
  }

  static getInternalStatusMap() {
    return {
      [this.INTERNAL_STATUS_UNDER_REVIEW]: 'Under Review',
      [this.INTERNAL_STATUS_ON_HOLD]: 'On Hold',
    };
  }

  static getInternalStatusName(id) {
    return this.getInternalStatusMap()[id];
  }

  static getShippingCompanyMap() {
    return {
      [this.SHIPPING_COMPANY_FEDEX]: 'FedEx',
      [this.SHIPPING_COMPANY_UPS]: 'UPS',
      [this.SHIPPING_COMPANY_OTHER]: 'Other'
    };
  }

  static getShippingCompanyName(id) {
    return this.getShippingCompanyMap()[id];
  }

  static getCustomerLocationMap() {
    return {
      [this.CUSTOMER_LOCATION_REMOTE]: 'Remote',
      [this.CUSTOMER_LOCATION_IN_STORE]: 'In store',
    };
  }

  static getCustomerLocationMap2() {
    return {
      [this.CUSTOMER_LOCATION_REMOTE]: 'Out of area (remote/offsite)',
      [this.CUSTOMER_LOCATION_IN_STORE]: 'In store',
    };
  }

  static getCustomerLocationName(id) {
    return this.getCustomerLocationMap()[id];
  }

  static getContractOptions() {
    return {
      [this.CONTRACT_OPTION_VID]: 'VerifyID Only',
      [this.CONTRACT_OPTION_SIGNING]: 'Document Signing',
      [this.CONTRACT_OPTION_SIGNING_VID]: 'Document Signing + VerifyID',
    };
  }

  static getContractTypeMap() {
    return {
      [this.CONTRACT_TYPE_ORIGINAL]: 'Original - Complete document package for signing',
      [this.CONTRACT_TYPE_RESIGN]: 'Resign - 1 or 2 documents that need resigning',
    };
  }

  static getContractTypeName(id) {
    return this.getContractTypeMap()[id];
  }

  static getItemToCollectMap() {
    return {
      [this.ITEM_TO_COLLECT_PROOF_OF_RESIDENCY]: 'Proof of Residency',
      [this.ITEM_TO_COLLECT_PROOF_OF_INCOME]: 'Proof of Income',
      [this.ITEM_TO_COLLECT_PROOF_OF_INSURANCE]: 'Proof of Insurance',
      [this.ITEM_TO_COLLECT_DRIVER_LICENSE_COPY]: "Copy of driver's license",
    };
  }

  static getAlertOptions() {
    return {
      [this.ALERT_FILTER_INACTIVE]: 'Inactive',
    };
  }

  static getItemToCollectName(id) {
    return this.getItemToCollectMap()[id];
  }

  static getDocDeliveryOptions() {
    return {
      [this.DOC_DELIVERY_OPTION_UPLOAD]: 'Upload my customer DOCS',
      [this.DOC_DELIVERY_OPTION_SHIPPING]: 'Shipping',
    };
  }

  static getRushOrderOptions() {
    return {
      1: 'Yes, I am striving for a faster completion time of 24-48 hours at $125',
      0: 'No, I am striving for a completion time of 3-5 business days',
    };
  }

  static getAOIOptions() {
    return {
      1: 'Yes (Highly Recommended) (It is mandatory for our group)',
      0: 'No (Our group requested NOT to collect the fingerprint on the Affidavit of Identity)',
    };
  }
}