import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { Button, UncontrolledTooltip } from "reactstrap";
import OrderDoc from "model/orderDoc";
import { showError, showSuccess } from "helpers/utilHelper";
import NoImg from "components/Shared/NoImg";
import { acceptOrderDocNormListItemPage, addPageBeforeOrderDocNormListItemPage, deleteOrderDocNormListItemPage, rejectOrderDocNormListItemPage } from "store/actions";
import 'photoswipe/dist/photoswipe.css';
import { Item } from 'react-photoswipe-gallery';
import InkSignRejectModal from "./RejectModal";
import InkSignPreviewModal from "./PreviewModal";
import { useInkSignPage } from "./PageContext";
import { perms, useAccess } from "context/access";
import { ORDER_DOC_CANNOT_DELETE_LAST_PAGE, ServerErrorException, UNABLE_SEND_REJECT_PAGE_NOTIF } from "helpers/errorHelper";
import { isNil } from "lodash";
import Confirmation from "components/Shared/Confirmation";
import classnames from "classnames";

const InkSignPage = ({ order, onImageError }) => {

  const { docId, pageNum, refreshDocHandler, page } = useInkSignPage();

  // get redux state from the store
  const doc = useSelector(state => state.OrderDoc.NormList.orderDocs[docId]);

  // get redux state from the store
  const orderDoc = useSelector(state => state.OrderDoc.NormList.orderDocs[docId]);

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // hooks that check permissions
  const { iAmGranted } = useAccess();

  /********** STATE **********/

  const [isRejectConfirmationVisible, setIsRejectConfirmationVisible] = useState(false);
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
  const [isImageError, setIsImageError] = useState(false);

  /********** EFFECTS **********/

  // runs whenever the page is accepted
  useEffect(() => {
    if (page.isAccepted === true) {
      showSuccess('Page has been accepted');
      refreshDocHandler();
    } else if (page.isAccepted === false) {
      showError('Unable to accept page');
    }
  }, [page.isAccepted]);

  // runs whenever the page is rejected
  useEffect(() => {
    if (page.isRejected === true) {
      showSuccess('Page has been rejected');
      refreshDocHandler();
    } else if (page.isRejected === false) {
      if (page.rejectError instanceof ServerErrorException) {
        if (page.rejectError.code == UNABLE_SEND_REJECT_PAGE_NOTIF) {
          // document has been rejected but the notifications could not be sent (at least some of them)
          // we want to make this distinction because the owner might want to resend notifications manually
          showSuccess('Page has been rejected');
          showError('Unable to send notifications');
          refreshDocHandler();
          return;
        }
      }
      showError('Unable to reject page');
    }
  }, [page.isRejected]);

  // runs whenever the page is deleted
  useEffect(() => {
    if (page.isDeleted === true) {
      showSuccess('Page has been deleted');
      refreshDocHandler();
    } else if (page.isDeleted === false) {
      if (parseInt(page.deleteError.code) == ORDER_DOC_CANNOT_DELETE_LAST_PAGE) {
        showError('A document must have at least one page. Delete entire document if needed.');
      } else {
        showError('Unable to delete page');
      }
    }
  }, [page.isDeleted]);

  // refresh current slide in order to display loading state for the action buttons
  useEffect(() => {
    if ([page.isAcceptInProgress, page.isRejectInProgress].find(item => !isNil(item))) {
      document.getElementById('refresh-slide')?.click();
    }
  }, [page.isAcceptInProgress, page.isRejectInProgress]);

  /********** EVENT HANDLERS **********/

  const acceptPage = () => dispatch(acceptOrderDocNormListItemPage(docId, pageNum));

  const rejectPage = reason => dispatch(rejectOrderDocNormListItemPage(docId, pageNum, { reason }));

  const promptRejectPage = () => setIsRejectConfirmationVisible(true);

  const deletePage = () => dispatch(deleteOrderDocNormListItemPage(docId, pageNum));

  const addPageBefore = () => dispatch(addPageBeforeOrderDocNormListItemPage(docId, pageNum));

  const promptDeletePage = () => setIsDeleteConfirmationVisible(true);

  const handleImageError = () => {
    setIsImageError(true);
    // flag the doc as having an error to restrict users from downloading/viewing it
    // this is useful when doc hasn't been uploaded in the platform, but was received by the dealer
    onImageError();
  };

  /********** OTHER **********/

  const isAcceptingPage = () => page.isAcceptInProgress;

  const isRejectingPage = () => page.isRejectInProgress;

  const isDeletingPage = () => page.isDeleteInProgress;

  const isAddingPage = () => page.isAddBeforeInProgress;

  const isAdditionalDoc = () => doc.numOfPages == 0;

  const canDeletePage = () => iAmGranted(perms.edit_orders) && !doc.isStdDoc && !isAdditionalDoc();

  const canAddPageBefore = () => iAmGranted(perms.edit_orders) && doc.status < OrderDoc.STATUS_COMPLETE && !doc.isStdDoc && !isAdditionalDoc();

  return <React.Fragment>
    <div className="ink-sign-page">
      <div className="ink-sign-page-title">Page {pageNum}</div>
      <div className="ink-sign-page-thumb">
        {(page.image && !doc.isWithdrawn) && <Item content={<InkSignPreviewModal docId={docId} pageNum={pageNum} onAcceptPage={() => acceptPage(pageNum)} onRejectPage={() => promptRejectPage(pageNum)} order={order} />}>
          {({ ref, open }) => (<img src={page.image} className="ink-sign-page-img" ref={ref} onClick={open} onError={handleImageError} />)}
        </Item>}
        {(doc.isWithdrawn && page.image) && <div className="text-center">Image no longer available.</div>}
        {!page.image && <NoImg />}
        {canDeletePage() && <React.Fragment>
          <Button type="button" color="danger" className={classnames("ink-sign-page-btn ink-sign-page-del-btn", { 'd-block': isDeletingPage() })} id={'ink-sign-page-del-btn-' + doc.id + '-' + pageNum} onClick={() => promptDeletePage()} disabled={isDeletingPage()}>
            {isDeletingPage() && <i className="mdi mdi-spin mdi-loading" />}
            {!isDeletingPage() && <i className="mdi mdi-delete"></i>}
          </Button>
          <UncontrolledTooltip placement="top" target={'ink-sign-page-del-btn-' + doc.id + '-' + pageNum}>Delete Page</UncontrolledTooltip>
        </React.Fragment>}
        {canAddPageBefore() && <React.Fragment>
          <Button type="button" color="secondary" className={classnames("ink-sign-page-btn ink-sign-page-add-btn", { 'd-block': isAddingPage() })} id={'ink-sign-page-add-btn-' + doc.id + '-' + pageNum} onClick={addPageBefore} disabled={isAddingPage()}>
            {isAddingPage() && <i className="mdi mdi-spin mdi-loading" />}
            {!isAddingPage() && <i className="mdi mdi-file-plus-outline"></i>}
          </Button>
          <UncontrolledTooltip placement="top" target={'ink-sign-page-add-btn-' + doc.id + '-' + pageNum}>Add Page Before</UncontrolledTooltip>
        </React.Fragment>}
      </div>
      {page.status == OrderDoc.PAGE_STATUS_NOT_SUBMITTED && <Button type="button" color="secondary" className="w-100 mt-2 cursor-default">
        Not submitted
        <i className="bx bx-x-circle ms-2 align-middle"></i>
      </Button>}
      {page.status == OrderDoc.PAGE_STATUS_ACCEPTED && <Button type="button" color="primary" className="w-100 mt-2 cursor-default">
        Accepted page
        <i className="bx bx-check-circle ms-2 align-middle"></i>
      </Button>}
      {page.status == OrderDoc.PAGE_STATUS_REJECTED && <Button type="button" color="danger" className="w-100 mt-2 cursor-default">
        Rejected page
        <i className="bx bx-x-circle ms-2 align-middle"></i>
      </Button>}
      {iAmGranted(perms.edit_orders) && page.status == OrderDoc.PAGE_STATUS_PENDING_REVIEW && doc?.status !== OrderDoc.STATUS_COMPLETE && <React.Fragment>
        <Button type="button" color="success" className="w-100 mt-2" onClick={() => acceptPage(pageNum)} disabled={isAcceptingPage()}>
          Accept page
          {isAcceptingPage() && <i className="bx bx bx-loader-alt bx-spin ms-2 align-middle" />}
          {!isAcceptingPage() && <i className="bx bx-check-circle ms-2 align-middle"></i>}
        </Button>
        {<Button type="button" color="danger" className="w-100 mt-2" onClick={() => promptRejectPage(pageNum)} disabled={isRejectingPage()}>
          Reject page
          {isRejectingPage() && <i className="bx bx bx-loader-alt bx-spin ms-2 align-middle" />}
          {!isRejectingPage() && <i className="bx bx-x-circle ms-2 align-middle"></i>}
        </Button>}
      </React.Fragment>}
    </div>
    <InkSignRejectModal isOpen={isRejectConfirmationVisible} onCancel={() => setIsRejectConfirmationVisible(false)} onReject={reason => {
      rejectPage(reason);
      setIsRejectConfirmationVisible(false);
    }} mode="page" order={order} orderDoc={orderDoc} />
    {isDeleteConfirmationVisible && <Confirmation
      confirmBtnText="Delete"
      onConfirm={() => {
        setIsDeleteConfirmationVisible(false);
        deletePage();
      }}
      onCancel={() => setIsDeleteConfirmationVisible(false)}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to delete page &quot;{pageNum}&quot;?</span>
    </Confirmation>}
  </React.Fragment>
}

InkSignPage.propTypes = {
  order: PropTypes.object,
  onImageError: PropTypes.func,
};

export default InkSignPage;
