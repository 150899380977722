import React, { useEffect } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Table, Alert } from "reactstrap";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { doOrderVidCleanup, getOrderVid } from "store/actions";
import SpinnerChase from "components/Shared/SpinnerChase";
import VidRequest from "model/vidRequest";
import Order from "model/order";
import { formats, formatTimestamp } from "helpers/dateHelper";
import { getBeUrl } from "helpers/utilHelper";
import config from "config";

const VidInformation = props => {

  const { orderId, signerNum, order } = props;

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  /********** STATE **********/

  const isInStoreOrder = () => order?.customerLocation === Order.CUSTOMER_LOCATION_IN_STORE;

  // get redux state from the store
  const { vidRequest, vidRequestError, isLoadInProgress } = useSelector(state => state.Order.Vid[signerNum]);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the vid data
    dispatch(getOrderVid(orderId, signerNum));
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderVidCleanup(signerNum));
    }
  }, [orderId]);

  /********** OTHER **********/

  const getImageUrl = image => getBeUrl(`vid-request/${vidRequest.id}/image/${image}`);

  const getCustomerAddress = vidRequest => {
    let customerInfo = vidRequest.autoResponse.customerInfo;
    return `${customerInfo.address.addressLine1}, ${customerInfo.address.city}, ${customerInfo.address.stateProvince}, ${customerInfo.address.postalCode}`
  }

  const getVerifClassName = score => {
    return 'score-col ' + (score < 50 ? 'scoreLow' : score > 85 ? 'scoreHigh' : 'scoreMedium');
  }

  const getVidResultClassName = status => {
    return "vid-result " + (status == VidRequest.STATUS_PASSED ? 'passed' : 'failed')
  }

  return <React.Fragment>
    {vidRequest && <>
      <Table className="table seal-table mb-4">
        <thead>
          <tr>
            <th className="label-col">Identity Validation</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Response</td>
            <td className="tb-value">{VidRequest.getStatusName(vidRequest.status)}</td>
          </tr>
          <tr>
            <td>Date</td>
            <td className="tb-value">{formatTimestamp(vidRequest.verificationTs, formats.DATETIME)}</td>
          </tr>
          <tr>
            <td>IP address</td>
            <td className="tb-value text-success">{vidRequest.ip}</td>
          </tr>
        </tbody>
      </Table>
      {vidRequest.idType == 'DRIVER_LICENSE' && <div className="vid-info-images">
        <div>
          <div className="vid-image-label">Front of the ID</div>
          <div className="vid-image-wrapper">
            <img src={getImageUrl(config.VID_REQ_IMAGE_FRONT)}></img>
          </div>
        </div>
        <div>
          <div className="vid-image-label">Back of the ID</div>
          <div className="vid-image-wrapper">
            <img src={getImageUrl(config.VID_REQ_IMAGE_BACK)}></img>
          </div>
        </div>
        {!isInStoreOrder() &&
          <div>
            <div className="vid-image-label">Selfie</div>
            <div className="vid-image-wrapper">
              <img src={getImageUrl(config.VID_REQ_IMAGE_SELFIE)}></img>
            </div>
          </div>}
      </div>}
      {vidRequest.idType == 'PASSPORT' && <div className="vid-info-images">
        <div>
          <div className="vid-image-label">Passport</div>
          <div className="vid-image-wrapper">
            <img src={getImageUrl(config.VID_REQ_IMAGE_PASSPORT)}></img>
          </div>
        </div>
        <div>
          <div className="vid-image-label">Selfie</div>
          <div className="vid-image-wrapper">
            <img src={getImageUrl(config.VID_REQ_IMAGE_SELFIE)}></img>
          </div>
        </div>
      </div>}
      {vidRequest?.autoResponse && <Table className="table seal-table mb-4">
        <thead>
          <tr>
            <th className="label-col"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Customer</td>
            <td className="tb-value">{vidRequest.autoResponse.customerInfo.name.fullName}</td>
          </tr>
          <tr>
            <td>Customer address</td>
            <td className="tb-value">{getCustomerAddress(vidRequest)}</td>
          </tr>
          <tr>
            <td>Date of birth</td>
            <td className="tb-value">{vidRequest.autoResponse.customerInfo.birthDate}</td>
          </tr>
          <tr>
            <td>Document number</td>
            <td className="tb-value">{vidRequest.autoResponse.customerInfo.documentNumber}</td>
          </tr>
          <tr>
            <td>Document expiration date</td>
            <td className="tb-value">{vidRequest.autoResponse.customerInfo.expirationDate}</td>
          </tr>
        </tbody>
      </Table>}

      {vidRequest.hasSignatureImage &&
        <div className="d-flex align-items-center">
          <p className="font-size-14 me-5 mb-0 font-weight-600">Customer signature:</p>
          <img className="vid-signature-image ms-5 me-0" src={getImageUrl(config.VID_REQ_IMAGE_SIGNATURE)} />
        </div>
      }
      <div className="verif-alerts mt-5">Verification alerts:</div>
      <div className="verif-alerts">Response: <span className={getVidResultClassName(vidRequest.status)}>{VidRequest.getStatusName(vidRequest.status)}</span></div>
      <div className="verif-alerts mb-1">Date: <span className="vid-date">{formatTimestamp(vidRequest.verificationTs, formats.DATETIME)}</span></div>
      {vidRequest?.autoResponse && <Row>
        {vidRequest.autoResponse.verifications.length > 5 ? <>
          <Col lg={3} md={6}>
            <Table className="table vid-verifications-table mb-4">
              <thead>
                <tr>
                  <th>NAME</th>
                  <th className="score-col">SCORE</th>
                </tr>
              </thead>
              <tbody>
                {vidRequest.autoResponse.verifications.splice(0, 5).map((verif, index) =>
                  <tr key={index}>
                    <td>{verif.name}</td>
                    <td className={getVerifClassName(verif.probability / 10)}>{parseInt(verif.probability / 10)} %</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
          <Col lg={3} md={6}>
            <Table className="table vid-verifications-table mb-4 ms-5">
              <thead>
                <tr>
                  <th>NAME</th>
                  <th className="score-col">SCORE</th>
                </tr>
              </thead>
              <tbody>
                {vidRequest.autoResponse.verifications.map((verif, index) =>
                  <tr key={index}>
                    <td>{verif.name}</td>
                    <td className={getVerifClassName(verif.probability / 10)}>{parseInt(verif.probability / 10)} %</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </> :
          <Col lg={3} md={6}>
            <Table className="table vid-verifications-table mb-4 ms-5">
              <thead>
                <tr>
                  <th>NAME</th>
                  <th className="score-col">SCORE</th>
                </tr>
              </thead>
              <tbody>
                {vidRequest.autoResponse.verifications.map((verif, index) =>
                  <tr key={index}>
                    <td>{verif.name}</td>
                    <td className={getVerifClassName(verif.probability / 10)}>{parseInt(verif.probability / 10)} %</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        }
      </Row>}
    </>}
    {isLoadInProgress && <Row>
      <Col>
        <SpinnerChase className="sm mt-3 mb-5" />
      </Col>
    </Row>}
    {vidRequestError && <Row>
      <Col>
        <Alert color="danger" className="fade show text-center">
          <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load identity verification
        </Alert>
      </Col>
    </Row>}
  </React.Fragment>
}

VidInformation.propTypes = {
  orderId: PropTypes.number.isRequired,
  signerNum: PropTypes.number.isRequired,
  order: PropTypes.object,
};

export default VidInformation;
