import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { useAuth } from "context/auth";
import { route, routes } from "helpers/routeHelper";

const Actions = () => {

  // hook that gets the user from context
  const { user } = useAuth();

  return <>
    <Card className="action-card">
      <CardBody className="d-flex align-items-center">
        <div className="d-flex align-items-center">
          <div className="icon-circle avatar-sm align-self-center">
            <span className="stats-icon purple-icon-color">
              <i className="bx bx-user font-size-24" />
            </span>
          </div>
          <Link to={route(routes.new_user)} className="action-link">
            <p className="font-size-24 mb-0">Create new user</p>
          </Link>
        </div>
      </CardBody>
    </Card>
    <Card className="action-card">
      <CardBody className="d-flex align-items-center">
        <div className="d-flex align-items-center">
          <div className="icon-circle avatar-sm align-self-center">
            <span className="stats-icon primary-icon-color-light">
              <i className="mdi mdi-plus font-size-24" />
            </span>
          </div>
          <Link to={route(routes.new_order)} className="action-link create-order-dashboard">
            <p className="font-size-24 mb-0">Create new order</p>
          </Link>
        </div>
      </CardBody>
    </Card>
    <Card className="action-card">
      <CardBody className="d-flex align-items-center">
        <div className="d-flex align-items-center">
          <div className="icon-circle avatar-sm align-self-center">
            <span className="stats-icon green-icon-color">
              <i className="far fa-edit font-size-20" />
            </span>
          </div>
          <div>
            <Link to={route(routes.view_dealer_store, user.dealerStoreId)} className="action-link">
              <p className="font-size-24 mb-0">Edit store profile</p>
            </Link>
          </div>
        </div>
      </CardBody>
    </Card>
  </>
}

export default Actions;