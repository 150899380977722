import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import SpinnerChase from "components/Shared/SpinnerChase";
import { Link, } from "react-router-dom";
import { Card, CardBody, Row, Col, Alert } from "reactstrap";
import { getMeetAppUrl, getSharedTableOptions } from "helpers/utilHelper";
import { perms, useAccess } from 'context/access';
import { getDtFooterRowCount, getSharedPaginationOptions } from "helpers/utilHelper";
import { route, routes } from "helpers/routeHelper";
import { doCustomerCallDtCleanup, getCustomerCallDt } from 'store/actions';
import { formats, formatTimestamp } from 'helpers/dateHelper';
import DataTableFilters from './DataTableFilters';
import Meeting from 'model/meeting';
import config from 'config';

const DataTable = () => {

  const dispatch = useDispatch();
  const { iAmGranted } = useAccess();

  /********** STATE **********/

  const { customerCalls: rows, customerCallsError: rowsError, totalCount, listParams, isLoadInProgress } = useSelector(state => state.CustomerCall.Dt);
  const filters = useSelector(state => state.CustomerCall.DtFilters);

  // datatable PaginationProvider options
  const [paginationOptions, setPaginationOptions] = useState({
    ...getSharedPaginationOptions(),
    totalSize: totalCount,
    page: listParams.page,
    sizePerPage: listParams.pageSize,
    defaultSorted: [{
      dataField: listParams.sortBy,
      order: listParams.sortDir,
    }],
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // we do not get the list data here
    // instead we listen for changes on 'filters' state var and do it there (see below)
    // this is to avoid fetching the data twice (both on component mount and on filters changed)
    return () => {
      // state cleanup on component unmount
      dispatch(doCustomerCallDtCleanup());
    }
  }, []);

  // runs whenever 'totalCount' changes
  // which happens after the first remote call
  useEffect(() => {
    // now we know the total number of rows so let's update the pagination
    setPaginationOptions(options => ({
      ...options,
      totalSize: totalCount,
    }));
  }, [totalCount]);

  // runs whenever 'filters' changes
  // which happens after 'apply-filters' or 'clear-filters'
  // but also on component mount
  useEffect(() => {
    // refresh the list data based on the new filters
    dispatch(getCustomerCallDt({
      ...listParams,
      filters: filters,
      // reset the page number when filtering
      // otherwise the current page number might be higher than the total number of pages after the filtering
      page: 1,
    }));
    // update the pagination with the new page number
    setPaginationOptions(options => ({
      ...options,
      page: 1,
    }));
  }, [filters]);

  /********** EVENT HANDLERS **********/

  // runs whenever table params change (sorting, pagination, search, etc)
  const handleTableChange = (type, newState) => {
    // reset the page number when searching
    // otherwise the current page number might be higher than the total number of pages after the search
    if (type == 'search') {
      newState.page = 1;
    }
    // refresh the list data based on the new table params
    dispatch(getCustomerCallDt({
      ...listParams,
      sortBy: newState.sortField,
      sortDir: newState.sortOrder,
      pageSize: newState.sizePerPage,
      page: newState.page,
      search: newState.searchText,
    }));
    // update pagination
    setPaginationOptions(options => ({
      ...options,
      page: newState.page,
      sizePerPage: newState.sizePerPage,
    }));
  };

  return <React.Fragment>
    <Card className="paginated-table-card">
      <CardBody className="pt-3">
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField='id'
              columns={columns}
              data={rows}
              search={{ defaultSearch: listParams.search }}>
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col>
                      <div className="search-box d-inline-block">
                        <div className="position-relative">
                          <Search.SearchBar
                            {...toolkitProps.searchProps}
                            delay={config.DATA_TABLE_SEARCH_DELAY}
                          />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm="auto">
                      <div className="text-end">
                        {iAmGranted(perms.create_customer_calls) && <Link to={route(routes.new_customer_call)} className="btn btn-primary mb-2">
                          <i className="mdi mdi-plus me-1" />Initiate call
                        </Link>}
                        <DataTableFilters />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="table-responsive">
                        <BootstrapTable
                          {...getSharedTableOptions()}
                          noDataIndication={!rowsError && "No calls found"}
                          onTableChange={handleTableChange}
                          defaultSorted={paginationProps.defaultSorted}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                      {isLoadInProgress && <SpinnerChase className="sm dtable" />}
                      {!!rowsError && <Alert color="danger" className="fade show text-center">
                        <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load calls
                      </Alert>}
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-30">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline">
                        <SizePerPageDropdownStandalone
                          {...paginationProps}
                        />
                        <div className="d-inline ms-2">{getDtFooterRowCount(paginationProps, rows.length, totalCount)}</div>
                      </div>
                      <div className="text-md-right ms-auto">
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  </React.Fragment>
}

const columns = [{
  dataField: 'idx',
  text: '#',
  sort: false,
  formatter: (cellContent, row, rowIndex) => rowIndex + 1,
}, {
  dataField: 'userName',
  text: 'User',
  sort: true,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => <Link to={route(routes.view_user, row.userId)}>{cellContent}</Link>,
}, {
  dataField: 'customerName',
  text: 'Customer Name',
  sort: true,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => cellContent
}, {
  dataField: 'phone',
  text: 'Customer phone',
  sort: true,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => cellContent,
}, {
  dataField: 'email',
  text: 'Customer email',
  sort: true,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => cellContent,
}, {
  dataField: 'meetingStatus',
  text: 'Status',
  sort: true,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => Meeting.getStatusName(cellContent),
}, {
  dataField: 'createdTs',
  text: 'Date',
  sort: true,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => formatTimestamp(cellContent, formats.DATETIME),
}, {
  dataField: 'meetingUuid',
  text: 'Meeting Link',
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => <a target="_blank" rel="noreferrer" href={getMeetAppUrl(`/r/${cellContent}`)} className="btn btn-secondary">Join</a>
}];

export default DataTable;