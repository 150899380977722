import React, { useState } from "react";
import PropTypes from 'prop-types';
import { useNavigate, Link } from "react-router-dom";
import { Card, CardBody, Row, CardHeader, CardSubtitle, Button, Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
import classnames from "classnames";
import { route, routes } from "helpers/routeHelper";
import { formatTimestamp, formats } from "helpers/dateHelper";
import Confirmation from "components/Shared/Confirmation";
import { showSuccess, showError, formatPhone, getTimezoneAbbr } from "helpers/utilHelper";
import { perms, useAccess } from "context/access";
import Col from "components/Shared/Col";
import CardPreloader from "components/Shared/CardPreloader";
import { useAuth } from "context/auth";
import { ServerErrorException, UNABLE_DELETE_USER_DUE_TO_FOREIGN_CONSTRAINTS } from "helpers/errorHelper";
import UserActivityStatus from "components/Shared/UserActivityStatus";
import { deleteUser, getVerificationLink, impersonateUser, sendVerificationEmail } from "helpers/backendHelper";

const ViewInfo = props => {

  const { isRefreshing, user, toggleEditMode, id } = props;

  // router hook that helps redirect
  const navigate = useNavigate();
  // hooks that check permissions
  const { iAmGranted, iAmGrantedAny } = useAccess();
  // authenticated user
  const { user: authUser } = useAuth();

  /********** STATE **********/

  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
  const [isSendInProgress, setIsSendInProgress] = useState(false);
  const [isImpersInProgress, setIsImpersInProgress] = useState(false);

  // is the confirmation dialog visible or not
  // used to show/hide the delete warning
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
  const [isResendConfirmationVisible, setIsResendConfirmationVisible] = useState(false);
  const [dropdownMenuIsOpen, setDropdownMenuIsOpen] = useState(false);

  /********** EVENT HANDLERS **********/

  // delete-user event handler
  // make the remote call to delete the user
  const removeUser = () => {
    setIsDeleteInProgress(true);
    deleteUser(id)
      .then(response => {
        const userName = user.fullName;
        showSuccess(`User "${userName}" has been deleted`);
        navigate(route(routes.list_users));
      })
      .catch(ex => {
        if (ex instanceof ServerErrorException) {
          if (ex.code == UNABLE_DELETE_USER_DUE_TO_FOREIGN_CONSTRAINTS) {
            // User cannot be deleted because he has relations to other entities
            showError('This user is linked to other entities and cannot be deleted');
            return;
          }
        }
        showError('Unable to delete user');

      })
      .finally(() => {
        setIsDeleteInProgress(false);
      });
  };

  // impersonate-user event handler
  const masqueradeUser = () => {
    setIsImpersInProgress(true);
    impersonateUser(id)
      .then(response => {
        // redirect to homepage
        // this will check the authenticated user
        // and redirect to the appropriate app
        window.location.href = '/';
      })
      .catch(ex => {
        showError('Unable to impersonate user');
      })
      .finally(() => {
        setIsImpersInProgress(false);
      });
  };

  const startEmailVerification = () => {
    setIsSendInProgress(true);
    sendVerificationEmail(id)
      .then(response => {
        showSuccess(`Verification email sent to "${user.email}"`);
      })
      .catch(ex => {
        showError('Unable to send verification email');
      })
      .finally(() => {
        setIsSendInProgress(false);
      });
  };

  /********** OTHER **********/

  const copyVerificationLink = () => {
    getVerificationLink(id)
      .then(response => {
        navigator.clipboard.writeText(response.url);
        showSuccess('Verification link copied to clipboard');
      })
      .catch(ex => {
        showError('Unable to fetch verification link');
      });
  }

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className={classnames(user.isActive ? 'bg-transparent' : 'bg-warning bg-soft', 'pt-3')}>
        <Row>
          <Col>
            <h3>{user.fullName}</h3>
            <CardSubtitle className="text-muted">
              {user.isActive && <UserActivityStatus id={user.id} status={user.activityStatus} lastSeen={user.lastActiveTs} autoUpdate={true} size="md" className="me-2" />}
              {!user.isActive && 'Deactivated'}
            </CardSubtitle>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              {iAmGranted(perms.impersonate_users) && iAmGranted(perms.impersonate_user, user) && <Button type="button" color="outline-dark" className="mb-2 impersonate-user" onClick={masqueradeUser}>
                {isImpersInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                {!isImpersInProgress && <i className="mdi mdi-account-arrow-left me-1" />}
                Impersonate user
              </Button>}
              {iAmGranted(perms.edit_user, user) && <Button type="button" color="primary" className="ms-2 mb-2" onClick={toggleEditMode}>
                <i className="mdi mdi-pencil me-1" />Edit user
              </Button>}
              {iAmGranted(perms.delete_users) && iAmGranted(perms.delete_user, { userRoleId: user.userRoleId }) && user.id != authUser.id && <Button type="button" color="danger" className="ms-2 mb-2" onClick={() => { setIsDeleteConfirmationVisible(true) }} disabled={isDeleteInProgress}>
                {isDeleteInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                {!isDeleteInProgress && <i className="mdi mdi-delete me-1" />}
                Delete user
              </Button>}
              <Link to={route(routes.list_users)} className="btn btn-secondary ms-2 mb-2">
                <i className="mdi mdi-chevron-left me-1" />Back
              </Link>
              {iAmGranted(perms.edit_users) && !user.isEmailVerified && <Dropdown isOpen={dropdownMenuIsOpen} toggle={() => setDropdownMenuIsOpen(value => !value)} className="d-inline-block ms-2 mb-2 align-middle">
                <DropdownToggle tag="button" className="btn btn-default card-menu-btn">
                  <i className="bx bx-dots-horizontal-rounded" />
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem onClick={() => setIsResendConfirmationVisible(true)} disabled={isSendInProgress}>Resend verification link</DropdownItem>
                  <DropdownItem onClick={copyVerificationLink}>Copy verification link</DropdownItem>
                </DropdownMenu>
              </Dropdown>}
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs="12" xl="4">
            <Row className="mb-3">
              <label className="mb-0">Title</label>
              <div>{user.title ?? '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Role</label>
              <div>{user.userRoleName}</div>
            </Row>
          </Col>
          <Col xs="12" xl="4">
            <Row className="mb-3">
              <label className="mb-0">Email</label>
              <div>{user.email}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Phone</label>
              <div>{user.phone ? formatPhone(user.phone) : '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Timezone</label>
              <div>{user.timezone} ({getTimezoneAbbr(user.timezone)})</div>
            </Row>
          </Col>
          <Col xs="12" xl="4">
            {iAmGranted(perms.view_own_group_users) && <Row className="mb-3">
              <label className="mb-0">Region</label>
              <div>{user.dealerRegionName ? <Link to={route(routes.view_dealer_region, user.dealerRegionId)}>{user.dealerRegionName}</Link> : '--'}</div>
            </Row>}
            {iAmGrantedAny([perms.view_own_group_users, perms.view_own_region_users]) && <Row className="mb-3">
              <label className="mb-0">Store</label>
              <div>{user.dealerStoreName ? <Link to={route(routes.view_dealer_store, user.dealerStoreId)}>{user.dealerStoreName}</Link> : '--'}</div>
            </Row>}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs="4">
            <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary"></i> Created</h5>
            <p className="text-muted mb-0">{formatTimestamp(user.createdTs, formats.DATETIME)}</p>
          </Col>
          <Col xs="4">
            <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary"></i> Updated</h5>
            <p className="text-muted mb-0">{formatTimestamp(user.updatedTs, formats.DATETIME)}</p>
          </Col>
          <Col xs="4">
            <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary"></i> Last login</h5>
            <p className="text-muted mb-0">{user.lastAuthTs ? formatTimestamp(user.lastAuthTs, formats.DATETIME) : '--'}</p>
          </Col>
        </Row>
      </CardBody>
      {isRefreshing && <CardPreloader />}
    </Card>
    {isDeleteConfirmationVisible && <Confirmation
      confirmBtnText="Delete"
      onConfirm={() => {
        setIsDeleteConfirmationVisible(false);
        removeUser();
      }}
      onCancel={() => setIsDeleteConfirmationVisible(false)}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to delete user &quot;{user.fullName}&quot;?</span>
    </Confirmation>}
    {isResendConfirmationVisible && <Confirmation
      confirmBtnText="Send"
      onConfirm={() => {
        setIsResendConfirmationVisible(false);
        startEmailVerification();
      }}
      onCancel={() => setIsResendConfirmationVisible(false)}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to resend the verification email to &quot;{user.email}&quot;?</span>
    </Confirmation>}
  </React.Fragment>
}

ViewInfo.propTypes = {
  isRefreshing: PropTypes.bool,
  id: PropTypes.number,
  user: PropTypes.object,
  toggleEditMode: PropTypes.func,
};

export default ViewInfo;
