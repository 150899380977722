import React, { useEffect, useRef } from "react"
import { useLocation } from 'react-router-dom';

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { Link } from "react-router-dom"

import { route, routes } from "helpers/routeHelper"
import { perms, useAccess } from "context/access"
import { useAuth } from "context/auth"

const SidebarContent = () => {

  const location = useLocation();

  const ref = useRef();
  // hooks that check permissions
  const { iAmGranted, iAmNotGranted, iAmGrantedAny } = useAccess();
  const { user: authUser } = useAuth();

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        let activeFor = items[i].getAttribute('data-active-for');
        let isActiveFor = false;
        if (activeFor) {
          activeFor = activeFor.split('~');
          isActiveFor = activeFor.some(function (pattern) {
            var re = new RegExp(pattern);
            return re.test(pathName);
          });
        }
        if (pathName === items[i].pathname || isActiveFor) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [location.href])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    if (item.classList.contains('active')) {
      return;
    }
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">Menu</li>

            <li>
              <Link to={route(routes.home)} data-active-for="^\/dashboard($|\/(.*)+)">
                <i className="bx bx-home-circle"></i>
                <span>Dashboard</span>
              </Link>
            </li>

            {iAmGranted(perms.view_users) && <li>
              <Link to={route(routes.list_users)} data-active-for="^\/user($|\/(.*)+)">
                <i className="bx bxs-user"></i>
                <span>Users</span>
              </Link>
            </li>}

            {iAmGranted(perms.view_own_dealer_group) && <li>
              <Link to={route(routes.view_dealer_group, authUser.dealerGroupId)} data-active-for="^\/group($|\/(.*)+)">
                <i className="mdi mdi-account-group"></i>
                <span>Group</span>
              </Link>
            </li>}

            {iAmGranted(perms.view_own_dealer_region) && <li>
              <Link to={route(routes.view_dealer_region, authUser.dealerRegionId)} data-active-for="^\/region($|\/(.*)+)">
                <i className="fas fa-globe-americas"></i>
                <span>Region</span>
              </Link>
            </li>}

            {iAmGranted(perms.view_dealer_regions) && iAmNotGranted(perms.view_own_dealer_region) && <li>
              <Link to={route(routes.list_dealer_regions)} data-active-for="^\/region($|\/(.*)+)">
                <i className="fas fa-globe-americas"></i>
                <span>Regions</span>
              </Link>
            </li>}

            {iAmGranted(perms.view_own_dealer_store) && <li>
              <Link to={route(routes.view_dealer_store, authUser.dealerStoreId)} data-active-for="^\/store($|\/(.*)+)">
                <i className="mdi mdi-store"></i>
                <span>Store</span>
              </Link>
            </li>}

            {iAmGranted(perms.view_dealer_stores) && iAmNotGranted(perms.view_own_dealer_store) && <li>
              <Link to={route(routes.list_dealer_stores)} data-active-for="^\/store($|\/(.*)+)">
                <i className="mdi mdi-store"></i>
                <span>Stores</span>
              </Link>
            </li>}

            {iAmGranted(perms.view_group_usage_reports) && <li>
              <Link to={route(routes.view_group_usage_report)} data-active-for="^\/group-usage-report($|\/(.*)+)">
                <i className="mdi mdi-poll"></i>
                <span>Group usage report</span>
              </Link>
            </li>}

            {iAmGranted(perms.view_dealer_sales_reports) && <li>
              <Link to={route(routes.view_dealer_sales_report)} data-active-for="^\/dealer-sales-report($|\/(.*)+)">
                <i className="mdi mdi-chart-areaspline"></i>
                <span>Dealer sales report</span>
              </Link>
            </li>}

            {iAmGranted(perms.view_orders) && <li>
              <Link to={route(routes.list_orders)} data-active-for="^\/order($|\/(.*)+)">
                <i className="mdi mdi-shopping"></i>
                <span>Orders</span>
              </Link>
            </li>}

            {iAmGrantedAny([perms.view_statements, perms.view_payments]) && <li>
              <Link to="/#" className="has-arrow">
                <i className="mdi mdi-script-outline"></i>
                <span>Accounting</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                {iAmGranted(perms.view_statements) && <li>
                  <Link to={route(routes.list_dealers_statements)} data-active-for="^\/dealer-statement($|\/(.*)+)">Statements</Link>
                </li>}
                {iAmGranted(perms.view_payments) && <li>
                  <Link to={route(routes.list_payments)} data-active-for="^\/payment($|\/(.*)+)">Payments</Link>
                </li>}
              </ul>
            </li>}

            {iAmGranted(perms.view_customer_calls) && <li>
              <Link to={route(routes.list_customer_calls)} data-active-for="^\/customer-call($|\/(.*)+)">
                <i className="mdi mdi-headphones"></i>
                <span>Calls</span>
              </Link>
            </li>}

            {iAmGranted(perms.set_up_payment) && <li>
              <Link to={route(routes.view_subscription)} data-active-for="^\/subscription($|\/(.*)+)">
                <i className="mdi mdi-wallet-membership"></i>
                <span>Manage subscription</span>
              </Link>
            </li>}

          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

export default SidebarContent;
