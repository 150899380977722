import React, { useState, useEffect, useCallback } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from "reactstrap";
import classnames from "classnames";
import OrderDoc from "model/orderDoc";
import { getBeUrl, showError, showSuccess } from "helpers/utilHelper";
import { useSocketOn, useSubscribeToOrderDoc } from "hooks/socket";
import socketEvent from 'constants/socketEvent';
import { acceptESignOrderDocNormListItem, deleteOrderDocNormListItem, getOrderDocNormListItem, rejectESignOrderDocNormListItem, reprocessOrderDocNormListItem } from "store/actions";
import 'photoswipe/dist/photoswipe.css';
import { formats, formatTimestamp } from "helpers/dateHelper";
import pdfIcon from 'assets/images/pdf-file.svg';
import Confirmation from "components/Shared/Confirmation";
import DocViewer from "components/Shared/DocViewer";
import ESignRejectModal from "./RejectModal";
import { ServerErrorException, UNABLE_SEND_NEW_ORDER_DEALER_NOTIF, UNABLE_SEND_REJECT_DOC_NOTIF } from "helpers/errorHelper";
import { perms, useAccess } from "context/access";

const ESignDoc = props => {

  const { id, num, order, refreshListHandler, orderIsLocked } = props;

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // hooks that check permissions
  const { iAmGranted } = useAccess();

  /********** STATE **********/

  const orderDoc = useSelector(state => state.OrderDoc.NormList.orderDocs[id]);

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isDeleteConfVisible, setIsDeleteConfVisible] = useState(false);
  const [isAcceptConfVisible, setIsAcceptConfVisible] = useState(false);
  const [isRejectConfVisible, setIsRejectConfVisible] = useState(false);
  const [isEditActive, setIsEditActive] = useState(false);

  /********** EFFECTS **********/

  // runs whenever an order doc is deleted
  useEffect(() => {
    if (orderDoc.isDeleted === true) {
      showSuccess(`Document "${orderDoc.name}" has been deleted`);
      refreshListHandler();
    } else if (orderDoc.isDeleted === false) {
      showError('Unable to delete document');
    }
  }, [orderDoc.isDeleted]);

  // runs whenever processing is restarted for the order doc
  useEffect(() => {
    // no need to refresh on success here
    // because we have subscribed to order doc changes via socket
    if (orderDoc.isReprocessed === false) {
      showError('Unable to restart processing for document');
    }
  }, [orderDoc.isReprocessed]);

  // runs whenever an order doc is accepted
  useEffect(() => {
    // no need to refresh on success here
    // because we have subscribed to order doc changes via socket
    if (orderDoc.isAccepted === false) {
      if (orderDoc.acceptError instanceof ServerErrorException) {
        if (orderDoc.acceptError.code == UNABLE_SEND_NEW_ORDER_DEALER_NOTIF) {
          // document has been accepted but the notifications could not be sent (at least some of them)
          // we want to make this distinction because the owner might want to resend notifications manually
          showError('Unable to send notifications');
          return;
        }
      }
      showError('Unable to accept document');
    }
  }, [orderDoc.isAccepted]);

  // runs whenever an order doc is rejected
  useEffect(() => {
    // no need to refresh on success here
    // because we have subscribed to order doc changes via socket
    if (orderDoc.isRejected === false) {
      if (orderDoc.rejectError instanceof ServerErrorException) {
        if (orderDoc.rejectError.code == UNABLE_SEND_REJECT_DOC_NOTIF) {
          // document has been rejected but the notifications could not be sent (at least some of them)
          // we want to make this distinction because the owner might want to resend notifications manually
          showError('Unable to send notifications');
          return;
        }
      }
      showError('Unable to reject document');
    }
  }, [orderDoc.isRejected]);

  /********** OTHER **********/

  const refreshOrderDoc = useCallback(() => dispatch(getOrderDocNormListItem(orderDoc.id)), [orderDoc.id]);

  /********** SOCKET **********/

  // start receiving updates about this particular document
  useSubscribeToOrderDoc(orderDoc.id);

  const onOrderDocChanged = useCallback(data => {
    // this socket client is shared by the entire app
    // and here we are listening for an event that might be triggered by multiple documents
    // therefore we need to check whether this update refers to the current document
    if (data.id == orderDoc.id) {
      refreshOrderDoc();
    }
  }, [orderDoc.id, refreshOrderDoc]);

  // listen for changes on order documents
  useSocketOn(socketEvent.orderDocChanged, onOrderDocChanged);

  /********** EVENT HANDLERS **********/

  const removeOrderDoc = () => dispatch(deleteOrderDocNormListItem(orderDoc.id));

  const restartProcessing = () => dispatch(reprocessOrderDocNormListItem(orderDoc.id));

  const acceptOrderDoc = () => dispatch(acceptESignOrderDocNormListItem(orderDoc.id));

  const rejectOrderDoc = reason => dispatch(rejectESignOrderDocNormListItem(orderDoc.id, { reason }));

  /********** OTHER **********/

  const isReady = () => orderDoc.status > OrderDoc.STATUS_PENDING_INITIAL_PROCESSING;

  const isProcessingFailed = () => orderDoc.isProcessingFailed;

  const isPendingReview = () => orderDoc.status == OrderDoc.STATUS_PENDING_DEALER_REVIEW;

  const isBusy = () => orderDoc.isDeleteInProgress || orderDoc.isReprocessInProgress || orderDoc.isAcceptInProgress || orderDoc.isRejectInProgress;

  const canBeEdited = () => iAmGranted(perms.edit_orders) && !orderIsLocked && [OrderDoc.STATUS_PENDING_SIGNATURES_PLACEMENT, OrderDoc.STATUS_PENDING_CUSTOMER_SIGNATURE].includes(orderDoc.status);

  const canBeReviewed = () => iAmGranted(perms.edit_orders) && isPendingReview();

  const canBeReprocessed = () => iAmGranted(perms.edit_orders) && isProcessingFailed();

  const canBeDeleted = () => iAmGranted(perms.edit_orders) && !orderIsLocked;

  return <React.Fragment>
    {orderDoc && <tr className={classnames({ deleting: orderDoc.isDeleteInProgress, processing: [OrderDoc.STATUS_PENDING_INITIAL_PROCESSING, OrderDoc.STATUS_PENDING_FINAL_PROCESSING].includes(orderDoc.status) })}>
      <td title={'Id: ' + orderDoc.id}>{num}</td>
      <td>
        <img src={pdfIcon} className="ms-n1 me-1" />
        {orderDoc.name}
      </td>
      <td>
        <span className={`badge badge-lg rounded-pill ps-3 pe-3 bg-${OrderDoc.getStatusColor(orderDoc.status)}`}>{orderDoc.statusName}</span>
      </td>
      <td>{orderDoc.creatorFullName}</td>
      <td>{formatTimestamp(orderDoc.createdTs, formats.US_DATE)}</td>
      <td>{orderDoc.numOfPages}</td>
      <td>{orderDoc.signedTs ? formatTimestamp(orderDoc.signedTs, formats.US_DATE) : '--'}</td>
      <td className="doc-actions">
        <Dropdown isOpen={menuIsOpen} toggle={() => setMenuIsOpen(!menuIsOpen)} className="btn-group">
          <DropdownToggle color="default" className="dropdown-toggle">
            {isBusy() && <i className="bx bx-loader-alt bx-spin" />}
            {!isBusy() && <i className="bx bx-dots-horizontal-rounded" />}
          </DropdownToggle>
          <DropdownMenu end>
            {canBeEdited() && <DropdownItem onClick={() => setIsEditActive(true)}>Edit</DropdownItem>}
            {orderDoc.isWithdrawn ? <React.Fragment>
              {/* Preview/download links of order documents become unavailable if isWithdrawn is true */}
              {isReady() && <><DropdownItem tag="span" id="view-doc">View</DropdownItem><UncontrolledTooltip placement="top" target="view-doc">Document no longer available.</UncontrolledTooltip></>}
              {isReady() && <><DropdownItem tag="span" id='download-doc'>Download</DropdownItem><UncontrolledTooltip placement="top" target="download-doc">Document no longer available.</UncontrolledTooltip></>}
            </React.Fragment> :
              <React.Fragment>
                {isReady() && <DropdownItem tag="a" href={getBeUrl(`order-doc/${orderDoc.id}/pdf`)} target="_blank">View</DropdownItem>}
                {isReady() && <DropdownItem tag="a" href={getBeUrl(`order-doc/${orderDoc.id}/pdf/download`)} target="_blank">Download</DropdownItem>}
              </React.Fragment>
            }
            {canBeReviewed() && <DropdownItem onClick={() => setIsAcceptConfVisible(true)} disabled={orderDoc.isAcceptInProgress}>Accept</DropdownItem>}
            {canBeReviewed() && <DropdownItem onClick={() => setIsRejectConfVisible(true)} disabled={orderDoc.isRejectInProgress}>Reject</DropdownItem>}
            {canBeReprocessed() && <DropdownItem onClick={() => restartProcessing()} disabled={orderDoc.isReprocessInProgress}>Reprocess</DropdownItem>}
            {canBeDeleted() && <DropdownItem onClick={() => setIsDeleteConfVisible(true)} disabled={orderDoc.isDeleteInProgress}>Delete</DropdownItem>}
          </DropdownMenu>
        </Dropdown>
        {isDeleteConfVisible && <Confirmation
          confirmBtnText="Delete"
          onConfirm={() => {
            setIsDeleteConfVisible(false);
            removeOrderDoc();
          }}
          onCancel={() => setIsDeleteConfVisible(false)}>
          <span style={{ color: '#556EE6' }}>Are you sure you want to delete document &quot;{orderDoc.name}&quot;?</span>
        </Confirmation>}
        {isAcceptConfVisible && <Confirmation
          confirmBtnText="Accept"
          onConfirm={() => {
            setIsAcceptConfVisible(false);
            acceptOrderDoc();
          }}
          onCancel={() => setIsAcceptConfVisible(false)}>
          <span style={{ color: '#556EE6' }}>Are you sure you want to accept document &quot;{orderDoc.name}&quot;?</span>
        </Confirmation>}
        {isEditActive && <div className="doc-viewer-wrapper">
          <DocViewer docId={orderDoc.id} closeHandler={() => setIsEditActive(false)} />
        </div>}
        <ESignRejectModal isOpen={isRejectConfVisible} onCancel={() => setIsRejectConfVisible(false)} onReject={reason => {
          rejectOrderDoc(reason);
          setIsRejectConfVisible(false);
        }} />
      </td>
    </tr>}
  </React.Fragment >
}

ESignDoc.propTypes = {
  id: PropTypes.number.isRequired,
  num: PropTypes.number.isRequired,
  order: PropTypes.object.isRequired,
  refreshListHandler: PropTypes.func.isRequired,
  orderIsLocked: PropTypes.bool,
};

export default ESignDoc;
