import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Form, Label, Input, FormFeedback, Button, UncontrolledTooltip } from "reactstrap";
import * as Yup from "yup";
import classnames from "classnames";
import Select from "react-select";
import { useFormik } from "formik";
import Col from "components/Shared/Col";
import {
  doOrderFormCleanup,
  doOrderSingleCleanup,
  updateOrderContract,
  getOrderWithCustomerSigners,
} from "store/actions";
import { capitalize, dictionaryToSelectOptions, getBeUrl, nullsToEmptyStrings, showBriefSuccess, showError, notaryMustUploadDocsOptions } from "helpers/utilHelper";
import { ValidationException } from "helpers/errorHelper";
import { route, routes } from "helpers/routeHelper";
import Order from "model/order";
import DatePicker from "components/Shared/DatePicker";
import TextareaAutosize from "react-textarea-autosize";
import instructionsIcon from "assets/images/delivery-instructions-icon.svg";
import infoIcon from "assets/images/info-icon.svg";

const ShippingInstructions = () => (
  <div>
    <ol className="pt-2 ps-3">
      <li>
        Please send DOCS out ASAP to the notary listed on the Confirmation email.
      </li>
      <li>
        Please provide the tracking numbers in the Confirmation and Tracking tab when the labels are created.
      </li>
    </ol>
    <p className="pb-2 pt-2"><b>Red Flag Information</b></p>
    <p>
      {"As a reminder, MavSign strongly recommends all dealership compliance steps to be completed and each signer to be thoroughly vetted. An additional aid that MavSign offers is our VerifyID software that can be added for a nominal fee per signer, to each order. Please keep in mind that the dealership has access to customer information that MavSign is not privy to such as, the customer's social security number, employment verification, credit reports etc."}
    </p>
    <p className="pt-2 pb-3"><i>We do keep in mind that all of the below points can be exhibited by legitimate customers but smart friction is beneficial for the protection of the vehicle and dealership.”</i></p>
    <Link to={route(routes.view_red_flag)} target="_blank">Learn more about some common red flags</Link>
  </div>
)

const UploadInstructions = ({ isNotaryRequired }) => (
  <div>
    {isNotaryRequired && <>
      <p>
        All DOCS must be uploaded in one PDF file the same day the order is submitted.
      </p>
      <p className="fw-bold">
        Order is subject to cancellation if uploaded docs are not received the same day as order submittal.
      </p>
    </>}
    {!isNotaryRequired && <p className="pt-2">All DOCS must be uploaded as PDF files.</p>}
    <p>
      All areas such as signatures and/or initials MUST be marked on the physical DOCS prior to upload (such as highlighted, clearly marked X, sticky `sign here` tag, etc).
    </p>
    <p>
      The highlights/marks must be visible on uploaded docs.
    </p>
    <p>
      Return label must be included.
    </p>
    {!isNotaryRequired && <>
      <p className="pb-2 pt-3"><b>Red Flag Information</b></p>
      <p>
        {"As a reminder, MavSign strongly recommends all dealership compliance steps to be completed and each signer to be thoroughly vetted. An additional aid that MavSign offers is our VerifyID software that can be added for a nominal fee per signer, to each order. Please keep in mind that the dealership has access to customer information that MavSign is not privy to such as, the customer's social security number, employment verification, credit reports etc."}
      </p>
      <p className="pt-2 pb-3"><i>We do keep in mind that all of the below points can be exhibited by legitimate customers but smart friction is beneficial for the protection of the vehicle and dealership.”</i></p>
      <Link to={route(routes.view_red_flag)} target="_blank">Learn more about some common red flags</Link>
    </>}
    {isNotaryRequired && <>
      <br />
      <p>
        Document drop off will be based on signing appointment time/duration, return envelope retrieval and local FedEx/UPS hours.
      </p>
      <br />
      <p className="fw-bold">
        For Regular Orders: The order will be signed by a notary with a turn-around time of: 2-4 business days.
      </p>
      <br />
      <p className="fw-bold">
        For RUSH Orders: The order will be signed by a notary with a turn-around time of: next business day.
      </p>
      <br />
      <p>
        DOCS will be printed on letter size paper. Unclear DOCS will cause a delay in the signing turnaround time and could be rejected and requests for replacement DOCS will be made by Maverick.Maverick nor the notary will review the DOCS for accuracy once uploaded. Please make sure all information such as Customer Name, address etc. is accurate and correct.
      </p>
      <p className="pb-2 pt-3"><b>Red Flag Information</b></p>
      <p>
        {"As a reminder, MavSign strongly recommends all dealership compliance steps to be completed and each signer to be thoroughly vetted. An additional aid that MavSign offers is our VerifyID software that can be added for a nominal fee per signer, to each order. Please keep in mind that the dealership has access to customer information that MavSign is not privy to such as, the customer's social security number, employment verification, credit reports etc."}
      </p>
      <p className="pt-2 pb-3"><i>We do keep in mind that all of the below points can be exhibited by legitimate customers but smart friction is beneficial for the protection of the vehicle and dealership.”</i></p>
      <Link to={route(routes.view_red_flag)} target="_blank">Learn more about some common red flags</Link>
    </>}
  </div>
)

UploadInstructions.propTypes = {
  isNotaryRequired: PropTypes.bool,
}

const getInstructions = isNotaryRequired => ({
  [Order.DOC_DELIVERY_OPTION_SHIPPING]: <ShippingInstructions />,
  [Order.DOC_DELIVERY_OPTION_UPLOAD]: <UploadInstructions isNotaryRequired={isNotaryRequired} />
});

const FormNewContract = props => {

  const { id, defaultValues, navRoutes } = props;
  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // router hook that helps redirect
  const navigate = useNavigate();

  /********** STATE **********/

  // get redux state from the store
  const { order, isSaveInProgress, saved, saveError } = useSelector(state => state.Order.Form);
  const [nextRoute, setNextRoute] = useState('');

  /********** FORM CONFIG **********/
  const allowedFileTypes = ["image/jpeg", "image/png", "application/pdf"];

  const formInitialValues = {
    docsSentToNotaryTs: null,
    docDeliveryOption: '',
    itemsToCollect: [],
    additionalItemsToCollect: '',
    dealerInstructions: '',
    signer1Instructions: '',
    signer2Instructions: '',
    returnLabel: '',
    shippingCompany: '',
    notaryMustUploadDocs: null,
    ...nullsToEmptyStrings(defaultValues, false, ['docsSentToNotaryTs', 'itemsToCollect', 'notaryMustUploadDocs']),
  };
  if (!!formInitialValues.itemsToCollect) {
    // the ids coming from the BE are strings, we need to convert them to numbers in order to match the select options
    formInitialValues.itemsToCollect = formInitialValues.itemsToCollect.map(item => +item);
  }

  const formValidationSchema = {
    // mandatory field
    docDeliveryOption: Yup.number().required('Field is required'),
    shippingCompany: Yup.mixed().when(['docDeliveryOption'], {
      is: (docDeliveryOption) =>
        docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING,
      then: Yup.number().required('Field is required'),
      otherwise: Yup.number()
    }),
    // mandatory fields when notary is required and shipping has been selected
    docsSentToNotaryTs: Yup.mixed().when('docDeliveryOption', {
      is: docDeliveryOption => docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING && isNotaryRequired,
      then: Yup.number().nullable().required('Field is required'),
    }),
    // optional fields
    additionalItemsToCollect: Yup.string().trim().nullable(),
    dealerInstructions: Yup.string().trim().nullable(),
    signer1Instructions: Yup.string().trim().nullable(),
    signer2Instructions: Yup.string().trim().nullable(),
    returnLabel: Yup.mixed().test('fileType', 'File must be a pdf or an image', value => {
      if (!value || !isNaN(formik.values.returnLabel)) {
        // Allow if no file is selected or is a TS value
        return true;
      }
      return value || allowedFileTypes.includes(value.type);
    }).when('docDeliveryOption', {
      // product decision to make return label required when order is with upload
      is: docDeliveryOption => docDeliveryOption === Order.DOC_DELIVERY_OPTION_UPLOAD,
      then: Yup.string().nullable().required('Field is required'),
    }),
    // mandatory field when notary is required
    notaryMustUploadDocs: Yup.mixed().when('isNotaryRequired', {
      is: true,
      then: Yup.boolean().nullable().required('Field is required'),
    }),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(formValidationSchema),
    onSubmit: values => {
      // Check if a PDF file has been selected
      if (formik.values.returnLabel instanceof File) {
        const formData = new FormData();

        if (formik.values.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING) {
          // values.docsSentToNotaryTs is sometimes NULL (ex. when order does not have a notary)
          // however FormData cannot handle NULL values
          // so we need to convert it to an empty string
          formData.append("docsSentToNotaryTs", values.docsSentToNotaryTs ?? '');
          formData.append("shippingCompany", values.shippingCompany ?? '');
        } else {
          formData.append("docsSentToNotaryTs", "");
        }

        formData.append("returnLabel", values.returnLabel);
        formData.append("docDeliveryOption", values.docDeliveryOption);
        formData.append("additionalItemsToCollect", values.additionalItemsToCollect);
        formData.append("dealerInstructions", values.dealerInstructions);
        formData.append("signer1Instructions", values.signer1Instructions);
        formData.append("signer2Instructions", values.signer2Instructions);
        formData.append("notaryMustUploadDocs", values.notaryMustUploadDocs ?? '');

        dispatch(updateOrderContract(formData, id))
      } else {
        dispatch(updateOrderContract(values, id))
      }
    }
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the order data
    dispatch(getOrderWithCustomerSigners(id));
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderSingleCleanup());
      dispatch(doOrderFormCleanup());
    }
  }, []);

  // runs whenever the 'saved' flag changes
  // which happens after a save-order attempt
  useEffect(() => {
    if (saved === true) {
      showBriefSuccess('Contract information has been saved');
      // multiple wizard steps use the same redux store
      // however the redirect to the next step happens before the previous component unmounts
      // which leads to the store cleanup not being done by the time the next step reads the store
      // so here we do the cleanup before the redirect
      dispatch(doOrderFormCleanup());
      dispatch(doOrderSingleCleanup());
      navigate(route(nextRoute, order.id));
    } else if (saved === false) {
      showError('Unable to save order');
      // see if the save failed due to validation
      if (saveError instanceof ValidationException) {
        // show an error on each invalid field
        for (const [name, message] of Object.entries(saveError.fields)) {
          formik.setFieldError(name, message);
        }
      }
      // enable the save button
      formik.setSubmitting(false);
    }
  }, [saved]);

  /********** EVENT HANDLERS **********/

  // on change event handler that capitalizes user input
  const capitalizeTextOnChange = event => {
    const { name, id } = event.target;
    formik.setFieldValue(name || id, capitalize(event.target.value))
  };

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  /********** OTHER **********/

  const getItemToCollectOptions = () => dictionaryToSelectOptions(Order.getItemToCollectMap());

  const getDocumentDeliveryOptions = () => dictionaryToSelectOptions(Order.getDocDeliveryOptions());

  const getShippingCompanyOptions = () => {
    const options = dictionaryToSelectOptions(Order.getShippingCompanyMap());
    const otherCompanyOption = options.find(option => option.value === Order.SHIPPING_COMPANY_OTHER);
    // reorder the options by keeping `Other` as the last element
    return [...options.filter(option => option.value !== Order.SHIPPING_COMPANY_OTHER), otherCompanyOption];
  };

  const hasDocDeliveryOption = !!formik.values.docDeliveryOption;

  const isReturnLabelRequired = formik.values.docDeliveryOption === Order.DOC_DELIVERY_OPTION_UPLOAD;

  const isShipping = formik.values.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING;

  const isNotaryRequired = defaultValues.isNotaryRequired;

  const isInkSignRequired = defaultValues.isInkSignRequired;

  return <React.Fragment>
    <Form className="pt-4">
      <div className="card-section">
        <Row className="mb-4">
          <Col xl="6">
            <div className="mb-4">
              <Label>How are you sending out the DOCS? *</Label>
              <Select
                classNamePrefix="select2-selection"
                name="docDeliveryOption"
                options={getDocumentDeliveryOptions()}
                onChange={selected => formik.setFieldValue("docDeliveryOption", +selected.value)}
                onFocus={e => onFieldFocused(e, "docDeliveryOption")}
                value={getDocumentDeliveryOptions().find(option => option.value === formik.values.docDeliveryOption)}
                className={!!formik.errors.docDeliveryOption && "is-invalid"} />
              {!!formik.errors.docDeliveryOption && <FormFeedback type="invalid">{formik.errors.docDeliveryOption}</FormFeedback>}
            </div>
            {hasDocDeliveryOption && (
              <>
                {isShipping && (
                  <>
                    <div className="mb-4">
                      <Label>Shipping Company *</Label>
                      <Select
                        classNamePrefix="select2-selection"
                        name="shippingCompany"
                        options={getShippingCompanyOptions()}
                        onChange={selected => formik.setFieldValue("shippingCompany", selected.value)}
                        onFocus={e => onFieldFocused(e, "shippingCompany")}
                        value={getShippingCompanyOptions().find(option => option.value === formik.values.shippingCompany)}
                        className={!!formik.errors.shippingCompany && "is-invalid"}
                      />
                      {!!formik.errors.shippingCompany && <FormFeedback type="invalid">{formik.errors.shippingCompany}</FormFeedback>}
                    </div>
                    {isNotaryRequired &&
                      <div className="mb-4">
                        <Label>Date when documents will be sent to Notary *</Label>
                        <><img id='info-icon' src={infoIcon} className='ms-2' alt='info-icon' /><UncontrolledTooltip placement="top" target='info-icon'>Please be advised the notary is assigned based on the date submitted</UncontrolledTooltip></>
                        <DatePicker
                          name="docsSentToNotaryTs"
                          onChange={selected => formik.setFieldValue("docsSentToNotaryTs", selected)}
                          onFocus={onFieldFocused}
                          value={formik.values.docsSentToNotaryTs}
                          minDate={new Date().setHours(0, 0, 0, 0)}
                          invalid={!!formik.errors.docsSentToNotaryTs}
                          format="d M, Y" />
                        {!!formik.errors.docsSentToNotaryTs && <FormFeedback type="invalid">{formik.errors.docsSentToNotaryTs}</FormFeedback>}
                      </div>}
                  </>
                )}

                {isNotaryRequired && (
                  <>
                    <div className="mb-4">
                      <Label>
                        Items to collect by the MavSign notary?
                        {/* <i className="mdi mdi-information ms-1" id="itemsToCollectTooltip" />
                        <UncontrolledTooltip placement="top" target="itemsToCollectTooltip">TOOLTIP_PLACEHOLDER</UncontrolledTooltip> */}
                      </Label>
                      <Select
                        isMulti
                        classNamePrefix="select2-selection"
                        name="itemsToCollect"
                        options={getItemToCollectOptions()}
                        onChange={selected => formik.setFieldValue("itemsToCollect", selected?.map(item => item.value))}
                        onFocus={e => onFieldFocused(e, "itemsToCollect")}
                        value={getItemToCollectOptions().filter(option => formik.values.itemsToCollect?.includes(option.value))}
                        className={!!formik.errors.itemsToCollect && "is-invalid"} />
                      {!!formik.errors.itemsToCollect && <FormFeedback type="invalid">{formik.errors.itemsToCollect}</FormFeedback>}
                    </div>

                    <div className="mb-4">
                      <Label>Does the notary need to scan and upload signed DOCS? *</Label>
                      <Select
                        classNamePrefix="select2-selection"
                        name="notaryMustUploadDocs"
                        options={notaryMustUploadDocsOptions()}
                        onChange={selected => formik.setFieldValue('notaryMustUploadDocs', selected.value)}
                        onFocus={e => onFieldFocused(e, 'notaryMustUploadDocs')}
                        value={notaryMustUploadDocsOptions().find(option => option.value === formik.values.notaryMustUploadDocs)}
                        className={!!formik.errors.notaryMustUploadDocs && 'is-invalid'} />
                      {!!formik.errors.notaryMustUploadDocs && <FormFeedback type="invalid">{formik.errors.notaryMustUploadDocs}</FormFeedback>}
                      {!!formik.values.notaryMustUploadDocs &&
                        <div className="mt-1 font-size-11"><i className="mdi mdi-information-outline me-1 font-size-12"></i>The wet-signed DOCS will be dropped after they are scanned - turnaround time is based on FedEx/UPS pick-up schedules. This could result in an additional fee.</div>
                      }
                    </div>

                    <div className="mb-4">
                      <Label>
                        Additional Items to Collect
                        {/* <i className="mdi mdi-information ms-1" id="additionalItemsToCollectTooltip" />
                        <UncontrolledTooltip placement="top" target="additionalItemsToCollectTooltip">TOOLTIP_PLACEHOLDER</UncontrolledTooltip> */}
                      </Label>
                      <TextareaAutosize
                        minRows={1}
                        maxRows={5}
                        className={classnames('form-control', { 'is-invalid': !!formik.errors.additionalItemsToCollect })}
                        name="additionalItemsToCollect"
                        onChange={capitalizeTextOnChange}
                        onFocus={onFieldFocused}
                        value={formik.values.additionalItemsToCollect} />
                      {!!formik.errors.additionalItemsToCollect && <FormFeedback type="invalid">{formik.errors.additionalItemsToCollect}</FormFeedback>}
                    </div>

                    {!!defaultValues.signers && defaultValues.signers.length > 1 && (
                      <div className="mb-4">
                        <Label>Specific Signing Instructions </Label>
                        <Row className="mb-4 mt-2">
                          <Col className="d-flex no-wrap">
                            <Label className="text-nowrap mt-2 me-2">Signer 1</Label>
                            <Input
                              type="text"
                              className="form-control"
                              name="signer1Instructions"
                              onChange={capitalizeTextOnChange}
                              onFocus={onFieldFocused}
                              value={formik.values.signer1Instructions}
                              invalid={!!formik.errors.signer1Instructions}
                            />
                            {!!formik.errors.signer1Instructions && <FormFeedback type="invalid">{formik.errors.signer1Instructions}</FormFeedback>}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="d-flex no-wrap">
                            <Label className="text-nowrap mt-2 me-2">Signer 2</Label>
                            <Input
                              type="text"
                              className="form-control"
                              name="signer2Instructions"
                              onChange={capitalizeTextOnChange}
                              onFocus={onFieldFocused}
                              value={formik.values.signer2Instructions}
                              invalid={!!formik.errors.signer2Instructions}
                            />
                            {!!formik.errors.signer2Instructions && <FormFeedback type="invalid">{formik.errors.signer2Instructions}</FormFeedback>}
                          </Col>
                        </Row>
                      </div>
                    )}
                  </>
                )}

                {isInkSignRequired && (
                  <div className="mb-4">
                    <Label>Return Label (Upload PDF, PNG, JPG or JPEG) <span>{isReturnLabelRequired && '*'}</span></Label>
                    <Input
                      type="file"
                      className="form-control"
                      name="returnLabel"
                      onChange={(e) => formik.setFieldValue("returnLabel", e.currentTarget.files[0])}
                      onFocus={onFieldFocused}
                      invalid={!!formik.errors.returnLabel}
                    />
                    {!!formik.errors.returnLabel && (
                      <FormFeedback type="invalid">{formik.errors.returnLabel}</FormFeedback>
                    )}
                    {!!defaultValues.returnLabel && <a href={getBeUrl(`order/${id}/return-label/render`)} className='d-inline-block mt-2' target="_blank" rel="noreferrer">{defaultValues.returnLabel}</a>}
                  </div>)
                }
                <Col>
                  <Label>Dealer Special Instructions</Label>
                  <TextareaAutosize
                    className={classnames('form-control', { 'is-invalid': !!formik.errors.dealerInstructions })}
                    placeholder={"i.e. Instructions for the notary during the signing, instructions for multiple signers or if the buyer is a company, instructions for Mavsign team, any additional instructions regarding the timing of the signing, language preference and the return of the documents signed."}
                    minRows={3}
                    name="dealerInstructions"
                    onChange={capitalizeTextOnChange}
                    onFocus={onFieldFocused}
                    value={formik.values.dealerInstructions} />
                  {!!formik.errors.dealerInstructions && <FormFeedback type="invalid">{formik.errors.dealerInstructions}</FormFeedback>}
                </Col>
              </>
            )}
          </Col>
          <Col xl="6">
            {hasDocDeliveryOption && (
              <div className="delivery-instructions">
                <div className="delivery-instructions-header">
                  <img src={instructionsIcon} alt="delivery instructions" />
                  <div className="delivery-instructions-title">Instructions</div>
                </div>
                {getInstructions(isNotaryRequired)[formik.values.docDeliveryOption]}
              </div>
            )}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col className="text-end pt-4">
            {navRoutes.prev &&
              <Button type="button" color="primary" className="ms-2 btn-faded" onClick={() => { setNextRoute(navRoutes.prev); formik.handleSubmit() }} disabled={formik.isSubmitting}>
                {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                Previous
              </Button>}
            <Button type="button" color="primary" className="ms-2" onClick={() => { setNextRoute(navRoutes.next); formik.handleSubmit() }} disabled={formik.isSubmitting}>
              {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
              Next
            </Button>
          </Col>
        </Row>
      </div>
    </Form >
  </React.Fragment >
}

FormNewContract.propTypes = {
  id: PropTypes.number,
  defaultValues: PropTypes.object,
  navRoutes: PropTypes.object,
};

export default FormNewContract;