import React from 'react';
import { useDocViewer } from './Context';
import Confirmation from '../Confirmation';

const DocViewerCloseConf = () => {

  /**
   * Context vars (see: Context.js)
   */
  const { isCloseConfVisible, setIsCloseConfVisible, saveFieldsAndClose, closeViewer } = useDocViewer();

  return <React.Fragment>
    {isCloseConfVisible && <Confirmation
      confirmBtnText="Save and close"
      cancelBtnText="Close without saving"
      onConfirm={() => {
        setIsCloseConfVisible(false);
        saveFieldsAndClose();
      }}
      onCancel={() => {
        setIsCloseConfVisible(false);
        closeViewer();
      }}>
      <span style={{color:'#556EE6'}}>You have unsaved changes that will be lost! Do you want to save them?</span>
    </Confirmation>}
  </React.Fragment>

}

export default DocViewerCloseConf;