import React from 'react';
import { useAuth } from './auth';
import { getAdminAppUrl, getNotaryAppUrl, toUserModel } from 'helpers/utilHelper';
import UserRole from 'model/userRole';
import Message from 'model/message';

export const perms = {
  view_users: 'view_users',
  create_users: 'create_users',
  create_user: 'create_user',
  view_dealer_stores: 'view_dealer_stores',
  view_orders: 'view_orders',
  view_dealer_groups: 'view_dealer_groups',
  view_own_dealer_group: 'view_own_dealer_group',
  edit_dealer_groups: 'edit_dealer_groups',
  edit_dealer_special_instructions: 'edit_dealer_special_instructions',
  create_dealer_regions: 'create_dealer_regions',
  delete_dealer_regions: 'delete_dealer_regions',
  view_own_group_dealer_stores: 'view_own_group_dealer_stores',
  view_own_group_users: 'view_own_group_users',
  free_of_charge_access: 'free_of_charge_access',
  view_dealer_regions: 'view_dealer_regions',
  edit_dealer_regions: 'edit_dealer_regions',
  create_dealer_stores: 'create_dealer_stores',
  view_dealer_store_filter: 'view_dealer_store_filter',
  create_dealer_region_managers: 'create_dealer_region_managers',
  edit_dealer_stores: 'edit_dealer_stores',
  edit_users: 'edit_users',
  edit_user: 'edit_user',
  delete_users: 'delete_users',
  delete_user: 'delete_user',
  impersonate_users: 'impersonate_users',
  create_dealer_store_managers: 'create_dealer_store_managers',
  create_dealer_store_signers: 'create_dealer_store_signers',
  view_dealer_store_signers: 'view_dealer_store_signers',
  view_invoices: 'view_invoices',
  view_statements: 'view_statements',
  view_own_dealer_region: 'view_own_dealer_region',
  view_own_region_dealer_stores: 'view_own_region_dealer_stores',
  view_own_region_users: 'view_own_region_users',
  set_up_payment: 'set_up_payment',
  view_subscription: 'view_subscription',
  view_own_store_users: 'view_own_store_users',
  view_own_dealer_store: 'view_own_dealer_store',
  create_orders: 'create_orders',
  edit_orders: 'edit_orders',
  edit_order_shipping: 'edit_order_shipping',
  add_order_services: 'add_order_services',
  delete_orders: 'delete_orders',
  export_order_seal: 'export_order_seal',
  view_customer_calls: 'view_customer_calls',
  create_own_role_users: 'create_own_role_users',
  create_customer_calls: 'create_customer_calls',
  impersonate_user: 'impersonate_user',
  view_payments: 'view_payments',
  retry_order_payments: 'retry_order_payments',
  view_message_channel: 'view_message_channel',
  upload_supporting_documents: 'upload_supporting_documents',
  upload_preview_documents: 'upload_preview_documents',
  view_group_usage_reports: 'view_group_usage_reports',
  edit_coupa_information: 'edit_coupa_information',
  view_dealer_sales_reports: 'view_dealer_sales_reports',
  view_own_group_stores: 'view_own_group_stores'
};

const AccessContext = React.createContext();

const AccessProvider = props => {

  const { user: authUser } = useAuth();

  const iAmAdmin = () => authUser && authUser.isAdmin();
  const iAmScheduler = () => authUser && authUser.isScheduler();
  const iAmDealerGroupManager = () => authUser && authUser.isDealerGroupManager();
  const iAmDealerRegionalManager = () => authUser && authUser.isDealerRegionalManager();
  const iAmDealerStoreManager = () => authUser && authUser.isDealerStoreManager();
  const iAmDealerFinanceManager = () => authUser && authUser.isDealerFinanceManager();
  const iAmDealerSalesManager = () => authUser && authUser.isDealerSalesManager();
  const iAmDealerUpperManager = () => authUser && authUser.isDealerUpperManager();
  const iAmDealerLowerManager = () => authUser && authUser.isDealerLowerManager();
  const iAmDealerManager = () => authUser && authUser.isDealerManager();
  const iAmDealerStoreUser = () => authUser && authUser.isDealerStoreUser();
  const iAmDealerStoreLimitedUser = () => authUser && authUser.isDealerStoreLimitedUser();

  const iAmAdminType = () => authUser && authUser.isAdminType();
  const iAmDealerType = () => authUser && authUser.isDealerType();
  const iAmNotaryType = () => authUser && authUser.isNotaryType();

  const iAmGranted = (attribute, subject) => {
    switch (attribute) {
      case perms.view_users:
      case perms.create_users:
      case perms.view_dealer_stores:
      case perms.view_orders:
        return true;

      case perms.view_dealer_groups:
      case perms.view_own_dealer_group:
      case perms.edit_dealer_groups:
      case perms.create_dealer_regions:
      case perms.delete_dealer_regions:
      case perms.view_own_group_dealer_stores:
      case perms.view_own_group_users:
      case perms.edit_dealer_special_instructions:
        return iAmDealerGroupManager();

      case perms.view_own_group_stores:
        return iAmDealerRegionalManager() || iAmDealerGroupManager();

      case perms.free_of_charge_access:
      case perms.view_dealer_regions:
      case perms.edit_dealer_regions:
      case perms.create_dealer_stores:
      case perms.create_dealer_region_managers:
      case perms.view_dealer_store_filter:
        return iAmDealerUpperManager();

      case perms.edit_dealer_stores:
      case perms.edit_users:
      case perms.impersonate_users:
      case perms.create_dealer_store_managers:
      case perms.create_dealer_store_signers:
      case perms.view_dealer_store_signers:
      case perms.view_invoices:
      case perms.view_statements:
        return iAmDealerManager();

      case perms.create_user:
        if (subject.userRoleId < authUser.userRoleId) {
          // one can only create same or lower ranking users
          return false;
        }
        if (UserRole.isNotary(subject.userRoleId)) {
          return false; // only admin roles can add notaries
        }
        return true;

      case perms.edit_user:
        if (subject.id === authUser.id)
          // one can edit self
          return true;
        if (subject.userRoleId <= authUser.userRoleId) {
          // one can edit lower ranking users
          return false;
        }
        // one can only edit what one can create
        return iAmGranted(perms.create_user, subject);

      case perms.view_own_dealer_region:
      case perms.view_own_region_dealer_stores:
      case perms.view_own_region_users:
        return iAmDealerRegionalManager();

      case perms.view_subscription:
      case perms.set_up_payment:
      case perms.edit_coupa_information:
        return iAmDealerStoreManager();

      case perms.delete_users:
        return iAmDealerManager();

      case perms.delete_user:
        if (subject.userRoleId <= authUser.userRoleId) {
          // one can only delete lower ranking users
          return false;
        }
        // one can only delete what one can create
        return iAmGranted(perms.create_user, subject);

      case perms.view_own_dealer_store:
      case perms.create_customer_calls:
      case perms.view_customer_calls:
      case perms.add_order_services:
        return iAmDealerStoreUser();

      case perms.create_orders:
        return iAmDealerStoreUser() || iAmDealerRegionalManager() || iAmDealerGroupManager();

      case perms.edit_orders:
        return iAmDealerManager() || iAmDealerFinanceManager() || iAmDealerSalesManager();

      case perms.export_order_seal:
        return iAmDealerStoreUser();

      case perms.view_own_store_users:
        return iAmDealerStoreManager() || iAmDealerFinanceManager();

      case perms.retry_order_payments:
      case perms.view_payments:
        return iAmDealerStoreManager();

      case perms.create_own_role_users:
        return iAmDealerSalesManager();

      // chat
      case perms.view_message_channel:
        switch (subject.channelId) {
          case Message.CHANNEL_DEALER_CUSTOMER:
          case Message.CHANNEL_SUPPORT_DEALER:
          case Message.CHANNEL_SUPPORT_DEALER_CUSTOMER:
            return true;
          case Message.CHANNEL_SCHEDULER_DEALER:
            return subject.isNotaryRequired;
        }
        return false;

      case perms.impersonate_user:
        return iCanImpersonate(subject);

      case perms.edit_order_shipping:
        return belongsToMe(subject)

      case perms.delete_orders:
      default:
        return false;

      case perms.upload_supporting_documents:
      case perms.upload_preview_documents:
        return iAmDealerStoreManager() || iAmDealerFinanceManager() || iAmScheduler();

      // group usage reports
      case perms.view_group_usage_reports:
      case perms.view_dealer_sales_reports:
        return iAmDealerGroupManager() || iAmDealerRegionalManager() || iAmDealerStoreManager();
    }
  }

  const iAmNotGranted = (attribute, subject) => !iAmGranted(attribute, subject);

  const iAmGrantedAny = (attributes, subject) => attributes.some(attr => iAmGranted(attr, subject));

  const belongsToMe = subject => authUser.id == subject.userId;

  const getUserAppUrl = fragment => {
    if (iAmAdminType()) {
      return getAdminAppUrl(fragment);
    } else if (iAmNotaryType()) {
      return getNotaryAppUrl(fragment);
    }
  }

  const iCanImpersonate = user => {
    // turn the plain object into a User model
    // so we can use the custom methods
    user = toUserModel(user);
    // one can only impersonate levels below himself
    // not above and not the same level
    if (authUser.userRoleId >= user.userRoleId) {
      return false;
    }
    // only privileged dealer roles can impersonate
    // and they can only impersonate their own type
    return iAmDealerManager() && user.isDealerType();
  }

  const contextValue = {
    iAmGranted,
    iAmNotGranted,
    iAmGrantedAny,
    iAmAdmin,
    iAmScheduler,
    iAmDealerGroupManager,
    iAmDealerRegionalManager,
    iAmDealerStoreManager,
    iAmDealerFinanceManager,
    iAmDealerSalesManager,
    iAmDealerUpperManager,
    iAmDealerLowerManager,
    iAmDealerManager,
    iAmDealerStoreUser,
    iAmAdminType,
    iAmDealerType,
    getUserAppUrl,
  };

  return <AccessContext.Provider value={contextValue} {...props} />
}

// helper hook that makes context data available
export const useAccess = () => React.useContext(AccessContext);

export default AccessProvider;