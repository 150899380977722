/********** DATATABLE **********/

export const GET_ORDER_DT = 'GET_ORDER_DT'
export const GET_ORDER_DT_OK = 'GET_ORDER_DT_OK'
export const GET_ORDER_DT_ERR = 'GET_ORDER_DT_ERR'
export const DO_ORDER_DT_CLEANUP = 'DO_ORDER_DT_CLEANUP'

/********** DATATABLE FILTERS **********/

export const APPLY_ORDER_DT_FILTERS = 'APPLY_ORDER_DT_FILTERS'
export const PATCH_ORDER_DT_FILTERS = 'PATCH_ORDER_DT_FILTERS'
export const CLEAR_ORDER_DT_FILTERS = 'CLEAR_ORDER_DT_FILTERS'
export const UNSET_ORDER_DT_FILTERS = 'UNSET_ORDER_DT_FILTERS'
export const DO_ORDER_DT_FILTERS_CLEANUP = 'DO_ORDER_DT_FILTERS_CLEANUP'

/********** LIST **********/

export const GET_IN_PROGRESS_ORDER_LIST = 'GET_IN_PROGRESS_ORDER_LIST'
export const GET_ORDER_LIST_OK = 'GET_ORDER_LIST_OK'
export const GET_ORDER_LIST_ERR = 'GET_ORDER_LIST_ERR'
export const DO_ORDER_LIST_CLEANUP = 'DO_ORDER_LIST_CLEANUP'

/********** SINGLE **********/

export const GET_ORDER = 'GET_ORDER'
export const GET_ORDER_WITH_CUSTOMER_SIGNERS = 'GET_ORDER_WITH_CUSTOMER_SIGNERS'
export const GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES = 'GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES'
export const GET_ORDER_WITH_VEHICLES = 'GET_ORDER_WITH_VEHICLES'
export const GET_FULL_ORDER = 'GET_FULL_ORDER'
export const GET_ORDER_OK = 'GET_ORDER_OK'
export const GET_ORDER_WITH_CUSTOMER_SIGNERS_OK = 'GET_ORDER_WITH_CUSTOMER_SIGNERS_OK'
export const GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES_OK = 'GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES_OK'
export const GET_ORDER_WITH_VEHICLES_OK = 'GET_ORDER_WITH_VEHICLES_OK'
export const GET_FULL_ORDER_OK = 'GET_FULL_ORDER_OK'
export const GET_ORDER_ERR = 'GET_ORDER_ERR'
export const PATCH_ORDER = 'PATCH_ORDER'

export const SEAL_ORDER = 'SEAL_ORDER'
export const SEAL_ORDER_OK = 'SEAL_ORDER_OK'
export const SEAL_ORDER_ERR = 'SEAL_ORDER_ERR'

export const RESEND_CUSTOMER_NOTIFICATIONS = 'RESEND_CUSTOMER_NOTIFICATIONS'
export const RESEND_CUSTOMER_NOTIFICATIONS_OK = 'RESEND_CUSTOMER_NOTIFICATIONS_OK'
export const RESEND_CUSTOMER_NOTIFICATIONS_ERR = 'RESEND_CUSTOMER_NOTIFICATIONS_ERR'

export const START_CUSTOMER_MEETING = 'START_CUSTOMER_MEETING'
export const START_CUSTOMER_MEETING_OK = 'START_CUSTOMER_MEETING_OK'
export const START_CUSTOMER_MEETING_ERR = 'START_CUSTOMER_MEETING_ERR'

export const START_SCHEDULER_MEETING = 'START_SCHEDULER_MEETING'
export const START_SCHEDULER_MEETING_OK = 'START_SCHEDULER_MEETING_OK'
export const START_SCHEDULER_MEETING_ERR = 'START_SCHEDULER_MEETING_ERR'

export const DO_ORDER_SINGLE_CLEANUP = 'DO_ORDER_SINGLE_CLEANUP'

export const RESET_ORDER_ACTION_FLAG = 'RESET_ORDER_ACTION_FLAG'

/********** FORM **********/

export const CREATE_ORDER = 'CREATE_ORDER'
export const UPDATE_ORDER_CUSTOMER = 'UPDATE_ORDER_CUSTOMER'
export const UPDATE_ORDER_VEHICLE = 'UPDATE_ORDER_VEHICLE'
export const UPDATE_ORDER_CONTRACT = 'UPDATE_ORDER_CONTRACT'
export const UPDATE_ORDER_DOCS = 'UPDATE_ORDER_DOCS'
export const SUBMIT_ORDER = 'SUBMIT_ORDER'
export const SAVE_ORDER_OK = 'SAVE_ORDER_OK'
export const SAVE_ORDER_ERR = 'SAVE_ORDER_ERR'
export const DO_ORDER_FORM_CLEANUP = 'DO_ORDER_FORM_CLEANUP'

/********** VID **********/

export const GET_ORDER_VID = 'GET_ORDER_VID'
export const GET_ORDER_VID_OK = 'GET_ORDER_VID_OK'
export const GET_ORDER_VID_ERR = 'GET_ORDER_VID_ERR'
export const DO_ORDER_VID_CLEANUP = 'DO_ORDER_VID_CLEANUP'

/********** INK-SIGN DOCS **********/

export const GET_ORDER_INK_SIGN_DOCS = 'GET_ORDER_INK_SIGN_DOCS'
export const GET_ORDER_INK_SIGN_DOCS_OK = 'GET_ORDER_INK_SIGN_DOCS_OK'
export const GET_ORDER_INK_SIGN_DOCS_ERR = 'GET_ORDER_INK_SIGN_DOCS_ERR'
export const DO_ORDER_INK_SIGN_DOCS_CLEANUP = 'DO_ORDER_INK_SIGN_DOCS_CLEANUP'

/********** E-SIGN DOCS **********/

export const GET_ORDER_E_SIGN_DOCS = 'GET_ORDER_E_SIGN_DOCS'
export const GET_ORDER_E_SIGN_DOCS_OK = 'GET_ORDER_E_SIGN_DOCS_OK'
export const GET_ORDER_E_SIGN_DOCS_ERR = 'GET_ORDER_E_SIGN_DOCS_ERR'
export const DO_ORDER_E_SIGN_DOCS_CLEANUP = 'DO_ORDER_E_SIGN_DOCS_CLEANUP'

/********** SUPPORTING DOCS **********/

export const GET_ORDER_SUPPORTING_DOCS = 'GET_ORDER_SUPPORTING_DOCS'
export const GET_ORDER_SUPPORTING_DOCS_OK = 'GET_ORDER_SUPPORTING_DOCS_OK'
export const GET_ORDER_SUPPORTING_DOCS_ERR = 'GET_ORDER_SUPPORTING_DOCS_ERR'
export const DO_ORDER_SUPPORTING_DOCS_CLEANUP = 'DO_ORDER_SUPPORTING_DOCS_CLEANUP'

/********** PREVIEW DOCS **********/

export const GET_ORDER_PREVIEW_DOCS = 'GET_ORDER_PREVIEW_DOCS'
export const GET_ORDER_PREVIEW_DOCS_OK = 'GET_ORDER_PREVIEW_DOCS_OK'
export const GET_ORDER_PREVIEW_DOCS_ERR = 'GET_ORDER_PREVIEW_DOCS_ERR'
export const DO_ORDER_PREVIEW_DOCS_CLEANUP = 'DO_ORDER_PREVIEW_DOCS_CLEANUP'

/********** ACTIVITY **********/

export const GET_ORDER_ACTIVITY = 'GET_ORDER_ACTIVITY'
export const GET_ORDER_ACTIVITY_OK = 'GET_ORDER_ACTIVITY_OK'
export const GET_ORDER_ACTIVITY_ERR = 'GET_ORDER_ACTIVITY_ERR'
export const DO_ORDER_ACTIVITY_CLEANUP = 'DO_ORDER_ACTIVITY_CLEANUP'

/********** MESSAGES **********/

export const GET_ORDER_MESSAGES = 'GET_ORDER_MESSAGES'
export const GET_ORDER_MESSAGES_OK = 'GET_ORDER_MESSAGES_OK'
export const GET_ORDER_MESSAGES_ERR = 'GET_ORDER_MESSAGES_ERR'
export const DO_ORDER_MESSAGES_CLEANUP = 'DO_ORDER_MESSAGES_CLEANUP'
export const GET_LAST_ORDER_MESSAGE = 'GET_LAST_ORDER_MESSAGE'
export const GET_LAST_ORDER_MESSAGE_OK = 'GET_LAST_ORDER_MESSAGE_OK'
export const GET_LAST_ORDER_MESSAGE_ERR = 'GET_LAST_ORDER_MESSAGE_ERR'
export const GET_CHAT_MEMBERS = 'GET_CHAT_MEMBERS'
export const GET_CHAT_MEMBERS_OK = 'GET_CHAT_MEMBERS_OK'
export const GET_CHAT_MEMBERS_ERR = 'GET_CHAT_MEMBERS_ERR'