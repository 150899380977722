import React from 'react';

export const formatPaymentPlanDescription = value => {
  if (!value) {
    return '--';
  }
  const lines = value.split("\n").map((line, index) => {
    const icon = line.toLowerCase().startsWith('x ') ? 'bx bx-x' : 'bx bx-check';
    const text = line.replace(/^x /, '');
    return <p key={index}><i className={icon} />{text}</p>
  });
  return lines;
}

export const formatPaymentPlanPrice = plan => {
  const fees = [];
  if (parseInt(plan.subscriptionPrice)) {
    fees.push(<div className="payment-plan-selection-item-price-fee" key="subscriptionPrice">
      ${Number(plan.subscriptionPrice)}<span>/Month</span>
    </div>);
  }
  if (parseInt(plan.remotePrice)) {
    fees.push(<div className="payment-plan-selection-item-price-fee" key="remotePrice">
      ${Number(plan.remotePrice)}<span>/Signing</span>
    </div>);
  }
  if (plan.isFree) {
    return '$ Free';
  }
  return fees;
}